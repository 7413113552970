import React, { useState, memo, useMemo, useEffect } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Link,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Box,
  DialogActions,
  Checkbox,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
// import { Service } from "../..";
import { agendamentoOrganico, checarCpf, checarHorarios } from "../../../../services/agendamentoOrganico";
import { cpfMask, removeCpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from '../../../../utils/dateFormat';
import DateFnsUtils from '@date-io/date-fns';

import { useStyles } from "./style";
import { MdCheckCircleOutline } from "react-icons/md";
import toastMessage from "../../../../utils/handleToastMessage";
import { getService, getServiceWithParams } from "../../../../services/servicos";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import AsyncSelect from "../../../../Components/AsyncSelect";
import { useStateValue } from "../../../../providers/StateProvider";
import { handleOpenPrintAttendance, handlePrintAttendance } from "../../../../utils/handlePrintAttendance";
import { Agendamento } from "../../../../interfaces/agendamento";
import { getCheckInterval } from "../../../../services/agendamento";
import DialogUnavailableService from "./components/DialogUnavaibleService";


interface ErrorResponse {
  message: string;
  agendamento: string;
  nome: string;
  cpf: string;
  servico_slug: string;
  hora: string;
}

interface AntecipateSchedule {
  nome: string;
  cpf: string;
  servico_id: number;
  agendamento_id: string;
}

interface ServiceProps {
  id: number;
  setor: number;
  slug: string;
  titulo: string;
}
interface Props {
  openSchedulerDialog: boolean;
  setOpenSchedulerDialog: (openSchedulerDialog: boolean) => void;
  service_data: any[];
  unidade_slug: string;
  currentSchedule?: Agendamento;
  scheduleAnticipated?: boolean;
}

export interface SchedulerProps {
  id: number,
  cpf: string,
  servico: number,
  status: string,
  data_solicitacao: string,
  unidade: string,
  ticket: string,
  data: string,
  prioridade: boolean,
  user: null,
  hora: string,
  setor: {
    id: number;
    nome: string;
    sigla: string;
  },
  guiche: {
    id: number;
    ordem: number;
    tipo: string;
  },
  tipo: string,
  hora_inicio: string,
  para_dependente: boolean,
  nome: string,
  updated_at: string,
  tipo_documento: 'nenhum' | 'cpf' | 'rg' | 'titulo';
  documento: string | null;
  protocolo: string;
  unidade_nome: string;
}

interface IdentificationProps {
  key: 'rg' | 'titulo' | 'nenhum' | 'cpf';
  value: string;
}
const identification: IdentificationProps[] = [
  {
    key: 'rg',
    value: 'RG'
  },
  {
    key: 'titulo',
    value: 'Título de eleitor'
  },
  {
    key: 'nenhum',
    value: 'Não informar'
  },
]

function DialogNotAttend({
  setOpenSchedulerDialog,
  openSchedulerDialog,
  service_data,
  unidade_slug,
  currentSchedule,
  scheduleAnticipated,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [nome, setNome] = useState<string>('');
  const [buttonOff, setButtonOff] = useState<boolean>(false);
  const [nomeValidationMessage, setNomeValidationMessage] = useState<string | undefined>();
  const [cpf, setCpf] = useState<string>('');
  const [cpfValidationMessage, setCpfValidationMessage] = useState<string | undefined>();
  const [serviceSelected, setServiceSelected] = useState<ServiceProps>();
  const [IsSavedScheduler, setIsSavedScheduler] = useState(false);
  const [schedulerData, setSchedulerData] = useState<SchedulerProps>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loadingService, setLoadingService] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorAlertAntecipate, setErrorAlertAntecipate] = useState(false);
  const [identSelected, setIdentSelected] = useState<IdentificationProps>({ key: 'nenhum', value: 'Não informar' });
  const [hoursList, setHoursList] = useState<string[]>([]);
  const [hasCPF, setHasCPF] = useState<boolean>(false);
  const [documentErrorMessage, setDocumentErrorMessage] = useState<string | undefined>();
  const [documento, setDocumento] = useState<string | undefined>('');
  const [searchService, setSearchService] = useState<any>();
  const [unitySelected, setUnitySelected] = useState<string | undefined>();
  const [isFutureDate, setIsFutureDate] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [hourSelected, setHourSelected] = useState<string | undefined>();
  const [validateQueryService, setValidateQueryService] = useState<boolean>(true);
  const [onlyCpf, setOnlyCpf] = useState<boolean>(false);
  const [prioridade, setPrioridade] = useState<boolean>(false);
  const [prioridadeAlta, setPrioridadeAlta] = useState<boolean>(false);
  const [normal, setNormal] = useState<boolean>(false);
  const [{ user, currentTicketOffice }] = useStateValue();
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>();
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [loadingInterval, setLoadingInterval] = useState<boolean>(false);
  const [openUnavaibleService, setOpenUnavaibleService] = useState<boolean>(false);
  const [periodUnavaibleService, setPeriodUnavaibleService] = useState({ data_inicial: "", data_final: "" });
  const [msgErrorUnavaibleService, setmsgErrorUnavaibleService] = useState();
  const [isUnavaibleService, setIsUnavaibleService] = useState<boolean>(false);

  const classes = useStyles();
  const history = useHistory();

  const handleCheckHours = async (serviceID: number, unity: string, date?: Date) => {
    setLoadingService(true);
    try {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const { data } = await checarHorarios(
        token as string,
        serviceID,
        unity,
        isFutureDate ? "Presencial" : "Espontâneo",
        date
      );
      if (data?.horarios?.length > 0) {
        setHoursList(data.horarios);
        setErrorMessage(undefined);
      }
    } catch (error: any) {
      if (error?.response?.data) {
        setErrorMessage(error.response.data?.message);
      }
    } finally {
      setLoadingService(false);
    }
  }

  const checkCpf = async (cpf: string) => {
    try {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      setLoading(true);
      if (cpf.length === 14) {
        const { data }: any = await checarCpf({
          cpf: removeCpfMask(cpf),
          token: token || "",
        });
        if (data?.nome) {
          setNome(data.nome);
        }
      }
    } catch (error: any) {
      toastMessage({
        type: "error",
        message: error?.response?.data?.details || "Erro ao buscar CPF"
      });
      setNome('');
      setCpfValidationMessage('Cpf não encontrado');
    } finally {
      setLoading(false);
    }
  }

  const handleAntecipateSchedule = async (data: ErrorResponse) => {
    setButtonOff(true)
    const { cpf, servico_slug, agendamento, nome } = data;
    const service = await getService(servico_slug);

    await handleSchedulerOrganic(
      {
        nome,
        cpf,
        servico_id: service.data.results[0].id,
        agendamento_id: agendamento,
      }
    );

    setErrorAlertAntecipate(false)
  }


  const handleClose = () => {
    !loading && setOpenSchedulerDialog(false);
  };
  const handleDialogUnavaibleService = () => {
    setOpenUnavaibleService(true)
  };

  const handleSchedulerOrganic = async (antecipate?: AntecipateSchedule) => {
    let cpfWithoutMask = removeCpfMask(cpf);
    if (nome && nome.length < 6) {
      setNomeValidationMessage('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (hasCPF) {
      if (!cpfWithoutMask) {
        setCpfValidationMessage('Informe o CPF.');
        return;
      }
      if (cpfWithoutMask.length < 11) {
        setCpfValidationMessage('CPF inválido.');
        return;
      }
    } else {
      if (identSelected && (identSelected.key === 'rg' || identSelected.key === 'titulo') && !documento) {
        setDocumentErrorMessage('Informe o seu documento.');
        return;
      }
    }

    try {
      setLoading(true);
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );

      const serviceID = isFutureDate ? searchService?.data?.id : antecipate ? antecipate.servico_id : serviceSelected?.id;
      const serviceSetor = isFutureDate ? searchService?.data?.setor_id : serviceSelected?.setor;
      const unity = isFutureDate ? unitySelected : unidade_slug;
      const guiche_id = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;

      const { data, status } = await agendamentoOrganico(
        {
          token: token || '',
          nome: nome ? nome : antecipate ? antecipate.nome : '',
          cpf: (hasCPF || onlyCpf) ? cpfWithoutMask : antecipate ? antecipate?.cpf : null,
          servico: serviceID as number,
          setor: serviceSetor as number,
          tipo: isFutureDate ? "Presencial" : "Espontâneo",
          hora: hourSelected ?? null,
          unidade_slug: unity as string,
          para_dependente: false,
          documento: documento ? documento : null,
          tipo_documento: (hasCPF || onlyCpf) ? 'cpf' : identSelected?.key,
          guiche_id,
          date: isFutureDate ? selectedDate : undefined,
          prioridade,
          prioridade_alta: prioridadeAlta,
          agendamento_id: (currentSchedule && scheduleAnticipated) ? currentSchedule.id as number : antecipate ? antecipate.agendamento_id : undefined,
        }
      );

      if (status === 201) {
        toastMessage({
          type: "success",
          message: "Agendamento realizado com sucesso!",
        });
        setSchedulerData(data);
        setIsSavedScheduler(true);
      }
    } catch (err: any) {
      if (err?.response?.status === 406) {
        setErrorAlert(true);
        setErrorMessage(err?.response?.data?.message);
        if (err?.response?.data?.message === "Usuário já possui agendamento para este serviço nessa unidade no dia escolhido.") {
          setErrorAlertAntecipate(true);
          setErrorResponse(err?.response?.data);
        }
      }
      toastMessage({
        type: "error",
        message: err?.response?.data?.message || err?.response?.data?.detail || "Falha ao realizar agendamento!",
      });
    } finally {
      setLoading(false);
      setButtonOff(false);
    }
  };

  const itemFormatted = (value: any) => {
    return {
      value: value?.slug,
      label: value?.titulo,
      data: value || {},
    }
  }

  const listFormatted = (values: any[]) => {
    return values?.map((serviceData: any) => (itemFormatted(serviceData)));
  }

  const handleSearchService = async (search: string) => {
    setValidateQueryService(false);
    const tokenSiseci: string | null = sessionStorage.getItem(
      'gov_access_token_sso',
    );
    if (search.length > 2 && tokenSiseci) {
      setValidateQueryService(true);
      const { data } = await getServiceWithParams(
        {
          ativo: true,
          page: 1,
          publico_especifico: '',
          search,
          agendavel: true,
        },
        tokenSiseci,
      );



      const responseFormatted = listFormatted(data?.results || [])
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return responseFormatted;
    }
  };

  const handleSetService = async (value: any) => {
    checkIndisponibilidades(value?.data?.indisponibilidades);
    try {
      setLoading(true)
      if (!!value?.data?.slug) {
        const { data } = await getService(value?.data?.slug);
        if (!!data?.results?.length) {
          setSearchService(itemFormatted(data?.results[0] || {}));
          setOnlyCpf(!!!data?.results[0]?.agendamento_sem_cpf);
        }
      }
      setErrorMessage(undefined);
      setUnitySelected(undefined);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  function checkIndisponibilidades(value: any) {
    if (value?.length > 0) {
      const now = new Date();
      for (let indisponibilidade of value) {
        if (indisponibilidade.ativo) {

          const dataInicio = new Date(indisponibilidade.data_hora_inicio);
          const dataFim = new Date(indisponibilidade.data_hora_fim);

          if (now >= dataInicio && now <= dataFim) {
            setPeriodUnavaibleService({
              data_inicial: indisponibilidade.data_hora_inicio,
              data_final: indisponibilidade.data_hora_fim
            }
            )
            setmsgErrorUnavaibleService(indisponibilidade.motivo)
            setIsUnavaibleService(true)
            return true;
          }
        }

      }
    }
    else {
      setmsgErrorUnavaibleService(undefined)
      setIsUnavaibleService(false)
    }
    return false;
  }

  const handleIdentificationInfo = (data: SchedulerProps) => {
    switch (data?.tipo_documento) {
      case 'cpf':
        return `CPF: ${cpfMask(data?.cpf)}`;
      case 'rg':
        return `RG: ${data?.documento}`;
      case 'titulo':
        return `Título de eleitor: ${data?.documento}`;
      default:
        return "Não informado";
    }
  }

  const dateTomorrow = useMemo(() => {
    if (isFutureDate) {
      let todayDate = new Date();
      return todayDate.setDate(todayDate.getDate() + 1);
    }
    return new Date();
  }, [isFutureDate]);

  function isFormInvalid() {
    if (!currentSchedule && !scheduleAnticipated) {
      return (
        loading ||
        !nome ||
        (!isFutureDate ? !serviceSelected : false) ||
        (isFutureDate ? (!searchService || !unitySelected || !selectedDate || !hourSelected) : false) ||
        ((identSelected.key === 'rg' || identSelected.key === 'titulo') ? !documento : false) || isUnavaibleService
      )
    } else {
      return false
    }
  }

  useEffect(() => {
    if (currentSchedule && scheduleAnticipated) {
      const cpf = (currentSchedule?.cpf?.length > 0 ? currentSchedule.cpf : currentSchedule.user?.username) as string;
      if (currentSchedule?.nome === 's/n') {
        setNome(currentSchedule?.user?.first_name || '');
      } else {
        setNome(currentSchedule?.nome || '');
      }
      setCpf(cpfMask(cpf));
      setOnlyCpf(!!currentSchedule)
      setServiceSelected({
        id: currentSchedule?.servico.id,
        setor: currentSchedule?.setor?.id || 0,
        slug: currentSchedule?.servico.slug,
        titulo: currentSchedule?.servico.titulo,
      });
    } else if (!scheduleAnticipated) {
      setNome('');
      setCpf('');
      setOnlyCpf(false);
      setServiceSelected(undefined);
    }
  }, [currentSchedule, scheduleAnticipated])

  const handleCheckInterval = async (date?: Date) => {
    try {
      setLoadingInterval(true)
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const servico = searchService?.data?.id;
      const unidade = unitySelected;
      const data_inicio = !date ? new Date(new Date().getFullYear(),
        new Date().getMonth(), 1).toISOString() : date.toISOString();
      const data_fim = !date ? new Date(new Date().getFullYear(),
        new Date().getMonth() + 1, 0).toISOString() : new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
      const tipo = isFutureDate ? 'Presencial' : 'Espontâneo'

      const response = await getCheckInterval(
        token as string,
        servico as number,
        unidade as string,
        data_inicio.split('T')[0],
        data_fim.split('T')[0],
        tipo
      )

      if (response.status === 200) {
        setAvailableDates(response.data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingInterval(false)
    }
  }

  const isDateAvailable = (date: Date) => {
    const dateFormatted = date.toISOString().split('T')[0]
    return availableDates.includes(dateFormatted);
  }

  useEffect(() => {
    if (isFutureDate && searchService && unitySelected) {
      handleCheckInterval()
    }
  }, [isFutureDate, searchService, unitySelected])


  const sendServiceToChecking = async (value: any) => {
    try {
      setLoading(true)
      if (!!value?.slug) {
        const { data } = await getService(value?.slug);
        if (data?.results?.length) {
          checkIndisponibilidades(data?.results[0]?.indisponibilidades)
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    sendServiceToChecking(serviceSelected)
  }, [serviceSelected])


  return (
    <Dialog
      fullWidth
      open={openSchedulerDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: IsSavedScheduler ? 'none' : 'initial' }}>
        <Box className={classes.headerModal}>
          <Typography className={classes.titleModal}>
            Gerar Atendimento
          </Typography>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Box className={classes.boxRowPriority}>
            <Box>
              <Checkbox
                disabled={!!currentSchedule && scheduleAnticipated}
                checked={isFutureDate}
                onChange={() => setIsFutureDate((oldState) => !oldState)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box>
              <Typography className={classes.labelCheckbox}>
                Você deseja agendar este atendimento para uma data futura?
              </Typography>
            </Box>
          </Box>
          {loadingService ? (
            <Box className={classes.boxLoadingIcon}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isFutureDate ? (
                <FormControl
                  classes={{ root: classes.root }}
                  variant="outlined"
                  className={classes.formControl}
                  error={typeof (errorMessage) === 'string'}
                  required
                  disabled={scheduleAnticipated}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Selecione o serviço
                  </InputLabel >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Selecione o serviço"
                    error={typeof (errorMessage) === 'string'}
                    onChange={() => {
                      setErrorMessage(undefined);
                      setErrorAlert(false);
                    }}
                    value={serviceSelected?.id}
                    classes={{
                      select: classes.menuItem,
                    }}
                  >
                    {service_data?.map((service) => (
                      <MenuItem
                        onClick={() => {
                          setServiceSelected(service);
                          setOnlyCpf(!!!service?.agendamento_sem_cpf || false);
                          handleCheckHours(service.id, unidade_slug);
                        }}
                        value={service.id}
                        key={service.slug}
                      >
                        {service.titulo}
                      </MenuItem>
                    ))}
                  </Select>
                  {typeof (errorMessage) === 'string' && !msgErrorUnavaibleService &&(<>
                    <FormHelperText>{errorMessage}</FormHelperText>
                  </>
                  )}
                  {msgErrorUnavaibleService && (<>
                    <FormHelperText className={classes.errorWarning}>Serviço temporariamente indisponível. Para mais informações,
                      <Link className={classes.linkError} onClick={handleDialogUnavaibleService}>
                        {' ' + 'clique aqui'}
                      </Link></FormHelperText>

                  </>
                  )}
                </FormControl>
              ) : (
                <>
                  <AsyncSelect
                    name="scheduling"
                    onChange={handleSetService}
                    placeholder="Procurar serviço"
                    value={searchService}
                    loadOptions={handleSearchService}
                    noDataMessage="Nenhum serviço encontrado"
                    validate={validateQueryService}
                  />
                  {msgErrorUnavaibleService && (<>
                    <FormHelperText className={classes.errorWarning}>Serviço temporariamente indisponível. Para mais informações,
                      <Link className={classes.linkError} onClick={handleDialogUnavaibleService}>
                        {' ' + 'clique aqui'}
                      </Link></FormHelperText>

                  </>
                  )}
                </>
              )}
            </>
          )}
          <Box className={classes.boxRowPriority}>
            <Box>
              <Checkbox
                checked={hasCPF || onlyCpf || !!currentSchedule}
                onChange={() => setHasCPF((oldState) => !oldState)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={onlyCpf || scheduleAnticipated}
              />
            </Box>
            <Box>
              <Typography className={classes.labelCheckbox}>
                {
                  onlyCpf ?
                    "Esse serviço aceita somente CPF como identificador" :
                    "Usar CPF como identificador do cidadão?"
                }
              </Typography>
            </Box>
          </Box>
          {(hasCPF || onlyCpf) ? (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
              error={typeof (cpfValidationMessage) === 'string'}
            >
              <TextField
                value={cpf}
                onChange={(e) => {
                  setCpf(cpfMask(e.target.value));
                  setCpfValidationMessage(undefined);
                  checkCpf(e?.target?.value || '');
                  setIdentSelected({
                    ...identSelected,
                    key: 'nenhum',
                  });
                  setDocumento(undefined);
                }}
                variant="outlined"
                disabled={scheduleAnticipated}
                label="CPF do cidadão"
                error={typeof (cpfValidationMessage) === 'string'}
              />
              {typeof (cpfValidationMessage) === 'string' && (
                <FormHelperText>{cpfValidationMessage}</FormHelperText>
              )}
            </FormControl>
          ) : (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione uma opção de identificação
              </InputLabel >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Selecione uma opção de identificação"
                onChange={() => {
                  setErrorMessage(undefined);
                  setErrorAlert(false);
                }}
                value={identSelected?.value ?? ''}
                classes={{
                  select: classes.menuItem,
                }}
              >
                {identification.map((ident) => (
                  <MenuItem
                    onClick={() => {
                      setIdentSelected(ident);
                    }}
                    value={ident.value}
                    key={ident.key}
                  >
                    {ident.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {!hasCPF && identSelected && (identSelected.key === 'rg' || identSelected.key === 'titulo') && (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
              error={typeof (documentErrorMessage) === 'string'}
            >
              <TextField
                value={documento}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setDocumento(value);
                  setDocumentErrorMessage(undefined);
                }}
                variant="outlined"
                label={`${identSelected.value}`}
                error={typeof (documentErrorMessage) === 'string'}
              />
              {typeof (documentErrorMessage) === 'string' && (
                <FormHelperText>{documentErrorMessage}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControl}
            error={typeof (nomeValidationMessage) === 'string'}
          >
            <TextField
              required
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
                setNomeValidationMessage(undefined);
              }}
              variant="outlined"
              label="Nome do cidadão"
              disabled={
                typeof (errorMessage) === 'string' || scheduleAnticipated
              }
              error={typeof (nomeValidationMessage) === 'string'}
            />
            {typeof (nomeValidationMessage) === 'string' && (
              <FormHelperText>{nomeValidationMessage}</FormHelperText>
            )}
          </FormControl>
          {!isFutureDate && !currentTicketOffice?.unidade?.habilitar_externo && (<>
            <Typography style={{
              marginBottom: 10,
            }}>Tipo Atendimento</Typography>

            {!prioridade && (<>
              <Box className={classes.boxRowPriority}>
                <Box >
                  <Checkbox
                    checked={normal}
                    onChange={() => { setNormal(!normal) }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box>
                  <Typography className={classes.textTitlePriority}>Normal</Typography>
                  <Typography className={classes.textSubTitlePriority}>Atendimento direcionado ao público em geral.</Typography>
                </Box>
              </Box>
            </>)}

            {!normal && (<>
              <Divider />
              <Box className={classes.boxRowPriority}>
                <Box>
                  <Checkbox
                    checked={prioridade}
                    onChange={() => { setPrioridade(!prioridade) }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box className={classes.textTitlePriority}>
                  <Typography className={classes.textTitlePriority}>Prioridade</Typography>
                  <Typography className={classes.textSubTitlePriority}>A principal lei federal é a Lei 10.048/00, que estabelece, no artigo 1º, os grupos de pessoas que têm direito ao atendimento prioritário: pessoas com deficiência, idosos com idade igual ou superior a 60 anos.</Typography>
                </Box>
              </Box>
            </>)}


            {prioridade && (<>
              <Divider />
              <Box className={classes.boxRowPriority}>
                <Box>
                  <Checkbox
                    checked={prioridadeAlta}
                    onChange={() => { setPrioridadeAlta(!prioridadeAlta) }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Box className={classes.textTitlePriority}>
                  <Typography className={classes.textTitlePriority}>Alta Prioridade </Typography>
                  <Typography className={classes.textSubTitlePriority}>A Lei Federal 13.466/17, estabelece, os idosos acima de 80 anos possuem prioridade perante os outros idosos, gestantes, lactantes, pessoas com crianças de colo e obesos.</Typography>
                </Box>
              </Box>
            </>)}


          </>)}

          {isFutureDate && (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
              required
              error={(searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0)) || typeof (errorMessage) === 'string'}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione o local
              </InputLabel >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Selecione o local"
                error={(searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0))}
                disabled={!(searchService?.data?.servicosUnidade?.length > 0)}
                onChange={(event): void => {
                  setUnitySelected(event.target.value as string);
                  setErrorMessage(undefined);
                  setErrorAlert(false);
                }}
                classes={{
                  select: classes.menuItem,
                }}
                value={unitySelected ?? ''}
              >
                {searchService?.data?.servicosUnidade?.map((unity: any) => (
                  <MenuItem
                    value={unity.unidade.slug_unidade}
                    key={unity.id}
                  >
                    {unity.unidade.nome}
                  </MenuItem>
                ))}
              </Select>
              {((searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0)) || errorMessage) && (
                <FormHelperText>{`${errorMessage ? errorMessage : 'Nenhum local de atendimento disponível para este serviço.'}`} </FormHelperText>
              )}
            </FormControl>
          )}

          {isFutureDate && searchService?.data && unitySelected && (
            <>
              <div className={classes.separateLine} />
              <FormControl
                classes={{ root: classes.root }}
                disabled={!unitySelected}
                variant="outlined"
                className={classes.formControl}
              >
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage="Você não pode agendar em dias anteriores"
                    invalidDateMessage="A data informada é inválida"
                    minDate={dateTomorrow}
                    InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                    autoOk
                    disabled={!unitySelected || loadingInterval}
                    variant="inline"
                    required
                    inputVariant="outlined"
                    label="Selecionar Data"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    shouldDisableDate={(date) => !isDateAvailable(date as Date)}
                    onMonthChange={async (date) => await handleCheckInterval(date as Date)}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        setSelectedDate(date);
                        if (unitySelected && searchService?.data) {
                          handleCheckHours(searchService.data.id, unitySelected, date);
                        }
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>

              </FormControl>
              <FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
                required
                disabled={!(hoursList.length > 0) || typeof (errorMessage) === 'string'}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecionar Horário
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Selecionar Horário"
                  disabled={!(hoursList.length > 0) || typeof (errorMessage) === 'string'}
                  onChange={() => {
                    setErrorMessage(undefined);
                    setErrorAlert(false);
                  }}
                  value={hourSelected}
                  classes={{
                    select: classes.menuItem,
                  }}
                >
                  {hoursList?.map((hour) => (
                    <MenuItem
                      onClick={() => {
                        setHourSelected(hour);
                      }}
                      value={hour}
                      key={hour}
                    >
                      {hour}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
            </>
          )}
          {errorAlert && (
            <FormHelperText className={classes.textError}>
              {errorMessage}
            </FormHelperText>
          )}
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSchedulerOrganic()}
              disabled={
                isFormInvalid()
              }
            >
              {!loading ? "Gerar" : <CircularProgress className={classes.loading} />}
            </Button>
          </div>
        </DialogContent>
      </div>
      {schedulerData && (
        <div style={{ display: IsSavedScheduler ? 'initial' : 'none' }}>
          <DialogTitle className={classes.headerModal}>
            <Typography className={classes.titleModal}>
              Atendimento Gerado com Sucesso!
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.contentModal}>
            <MdCheckCircleOutline
              className={classes.icon}
            />
            <Box className={classes.boxInfo}>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Nome
                </Typography>
                <Typography className={classes.titleLight}>
                  {schedulerData?.nome}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Identificação
                </Typography>
                <Typography className={classes.titleLight}>
                  {handleIdentificationInfo(schedulerData)}
                </Typography>
              </Box>
              <Box className={classes.boxService}>
                <Typography className={classes.titleBolder}>
                  Serviço
                </Typography>
                <Typography className={classes.titleLight}>
                  {serviceSelected?.titulo ?? searchService?.data?.titulo}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Local de atendimento
                </Typography>
                <Typography className={classes.titleLight}>
                  {schedulerData?.unidade_nome}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  data/hora
                </Typography>
                <Typography className={classes.titleLight}>
                  {`${dateFormat(schedulerData?.data)} - ${schedulerData?.hora}`}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Protocolo
                </Typography>
                <Typography className={classes.titleLight}>
                  {schedulerData?.protocolo}
                </Typography>
              </Box>

            </Box>
          </DialogContent>

          <DialogActions className={classes.footerModal}>
            {!isFutureDate && schedulerData && <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (!currentTicketOffice?.unidade?.habilitar_externo) {
                  handlePrintAttendance(schedulerData)
                } else {
                  handleOpenPrintAttendance(
                    user.cpf,
                    schedulerData.data,
                    schedulerData.cpf,
                  );
                }
                history.go(0);
              }}
              className={classes.buttonClose}
            >
              {!currentTicketOffice?.unidade?.habilitar_externo ? "Imprimir senha e fechar" : "Gerar Senha"}
            </Button>}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.go(0)}
              className={classes.buttonClose}
            >
              Fechar
            </Button>


          </DialogActions>
        </div>
      )}
      {errorResponse && (
        <Dialog open={errorAlertAntecipate} onClose={() => setErrorAlert(false)}>
          <DialogTitle className={classes.headerModal}>
            <Typography className={classes.titleModal}>
              Atendimento já agendado
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.contentModal}>
            <Box>
              <Typography className={classes.textError}>
                {errorResponse?.message}
              </Typography>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Nome
                </Typography>
                <Typography className={classes.textModal}>
                  {errorResponse?.nome}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  CPF
                </Typography>
                <Typography className={classes.textModal}>
                  {cpfMask(errorResponse?.cpf)}
                </Typography>
              </Box>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>
                  Hora
                </Typography>
                <Typography className={classes.textModal}>
                  {errorResponse?.hora}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <Typography className={classes.textTitlePriority} style={{ textAlign: 'center' }}>
            Deseja antecipar este atendimento?
          </Typography>
          <DialogContent className={classes.footerModal}>
            <div className={classes.buttonCloseModal}>
              {!loading && (
                <Link className={classes.link} onClick={handleClose}>
                  fechar
                </Link>
              )}
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleAntecipateSchedule(errorResponse)}
                disabled={buttonOff}
              >
                {!loading ? "Antecipar" : <CircularProgress className={classes.loading} />}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <DialogUnavailableService open={openUnavaibleService}
        closeModal={setOpenSchedulerDialog}
        periodoInatividade={periodUnavaibleService}
        motivo={msgErrorUnavaibleService}
      />
    </Dialog>
  );
}

export default DialogNotAttend;