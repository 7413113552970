import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#053396",
      dark: "#053396",
    },
    secondary: {
      main: "#94C120",
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiStepLabel: {
      label: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
        padding: "8px 8px",
        textTransform: "none",
      },
    },
  },
  typography: {
    body1: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      textAlign: "center",
      textShadow: "0px 6px 14px rgba(24, 39, 75, 0.12)",
    },
    h3: {
      fontFamily: "Roboto",
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Roboto",
    },
    h6: {
      fontFamily: "Roboto",
      // textTransform: 'uppercase',
      fontWeight: "bold",
    },
  },
});

export default theme;
