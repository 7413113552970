import { Guiche } from "../interfaces/guiche";
import { UserData } from "../interfaces/user";

interface InitState {
  user: null | UserData,
  currentTicketOffice: null | Guiche,
}

export const initialState: InitState = {
  user: null,
  currentTicketOffice: null,
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_TICKET_OFFICE: "SET_TICKET_OFFICE",
};

const reducer = (state: any, action: { type: any; user: any; currentTicketOffice: any; }) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case actionTypes.SET_TICKET_OFFICE:
      return {
        ...state,
        currentTicketOffice: action.currentTicketOffice,
      }
    default:
      return new Error(`Unhandled  action type ${action.type}`);
  }
};

export default reducer;