import { toast } from 'react-toastify';

type Props = {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
  timer?: number;
};

export default function toastMessage({ message, type, timer }: Props) {
  toast(message, {
    type,
    autoClose: timer ||  5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}