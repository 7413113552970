/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Tabs,
  Tab,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Popover,
  Divider,
  MenuItem,
  Tooltip,
  TableCell,
  Icon,
} from "@material-ui/core";
import {
  AlarmOff,
  ArrowRight,
  CheckCircleOutline,
  ListAlt,
  LockOpen,
  Menu,
  People,
  PermPhoneMsg,
  PlayArrow,
  Print,
  Visibility,
} from "@material-ui/icons";
import { useSchedules } from "../../useSchedules";
import { StyledTableCell } from "../../style";
import { cpfMask } from "../../../../utils/cpfFormat";
import DependentsDialog from "../DependentsDialog";
import dateFormat from "../../../../utils/dateFormat";
import AttendanceDialog from "../AttendanceDialog";
import AttendanceDialogCancel from "../AttendanceDialogCancel";
import AttendanceDialogStop from "../AttendanceDialogCancelStop";
import TabAttendanceToday from "../TabAttendanceToday";
import TabOldsAttendance from "../TabOldsAttendance";
import TabAllAttendance from "../TabAllAttendance";
import { Agendamento } from "../../../../interfaces/agendamento";
import { useStateValue } from "../../../../providers/StateProvider";
import { TableRowCheckTime } from "../../../../Components";
import { MdCampaign, MdNoteAdd } from "react-icons/md";
import Timer from "../Timer";
import { Alert } from "@material-ui/lab";
import ModalPause from "../ModalPause";
import Modal156 from "../Modal156";
import ModalFreeze from "../ModalFreeze";
import Modal156ListDemand from "../Modal156ListDemand";
import ModalFormFlow from "../ModalFormFlow";
import ModalFormFlowDetails from "../ModalFormFlowDetails";
import SchedulingFilter from "../SchedulingFilter";
import PaginationService from "../../../../Components/PaginationService";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}


export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SchedulesContent(): JSX.Element {
  const [{ currentTicketOffice }] = useStateValue();

  const {
    classes,
    currentSchedule,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    setCurrentSchedule,
    setOpenAttendanceDialog,
    openAttendanceCancel,
    setOpenAttendanceCancel,
    openAttendanceStop,
    setOpenAttendanceStop,
    history,
    openDependentsDialog,
    schedulesDependentCurrent,
    openAttendanceDialog,
    matchesMobile,
    value,
    handleChange,
    dataSchedulesAll,
    anchorElMenu,
    handleCloseMenu,
    handleChangeIndex,
    handleClick,
    dataSchedulesToday,
    dataSchedulesNext,
    dataSchedulesOlds,
    getHrefComprovante,
    handleCallPass,
    callingPass,
    remainingTime,
    timerKey,
    handleUnlockPass,
    callingUnlockPass,
    agendamentosDaVezHoje,
    addUrlSearchParams,
    loadingSchedulesNext
  } = useSchedules();
  const [open156, setOpen156] = useState(false);
  const [open156Demand, setOpen156Demand] = useState(false);
  const [openFormFlow, setOpenFormFlow] = useState(false);
  const [openFormFlowDetails, setOpenFormFlowDetails] = useState(false);
  const [openFreeze, setOpenFreeze] = useState(false);

  const [protocolSelected, setProtocolSelected] = useState("");
  const [protocolFormSelected, setProtocolFormSelected] = useState("");
  const [scheduleSelected, setScheduleSelected] = useState<
    undefined | Agendamento
  >();
  const [typeSelected, setTypeSelected] = useState("");
  const [cpfSelected, setCpfSelected] = useState("");
  const [cpfAtendenteSelected, setCpfAtendenteSelected] = useState("");
  const [slugServicoSelected, setSlugServicoSelected] = useState("");

  const searchParams =  new URLSearchParams(window.location.search);

  const tipo_prioridade = searchParams.get("tipo_prioridade")!
  const cpf = searchParams.get("cpf")!
  const nome = searchParams.get("nome")!
  const senha = searchParams.get("senha")!
  const status = searchParams.get("status")!
  const tipo = searchParams.get("tipo")!
  const page = searchParams.get("page")!

  const handleOpen156 = (protocol: string, cpf: string, type: string) => {
    setOpen156(true);
    setProtocolSelected(protocol);
    setCpfSelected(cpf);
    setTypeSelected(type);
  };

  const handleOpenFormFlow = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || "");
    setSlugServicoSelected(schedule.servico.slug || "");
    setCpfSelected(schedule.user?.username || schedule.cpf || "");
    setOpenFormFlow(true);
  };

  const handleOpenFormFlowDetails = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || "");
    setProtocolFormSelected(schedule.protocolo_formflow || "");
    setOpenFormFlowDetails(true);
  };

  const handleOpen156Demanda = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || "");
    setCpfAtendenteSelected(schedule.atendente_id || "");
    setOpen156Demand(true);
  };

  const handleOpenFreeze = (schedule: Agendamento) => {
    setOpenFreeze(true);
    setScheduleSelected(schedule);
  };

  const setNewPage = async (newPage: number) => {
    await agendamentosDaVezHoje({
      page: Number(newPage),
      tipo_prioridade,
      cpf,
      nome,
      senha,
      status,
      tipo,
    })
  }

  useEffect(() => {
    if (!dataSchedulesNext) {
      const interval = setInterval(() => {
        agendamentosDaVezHoje({
          page: Number(page) || 1,
          tipo_prioridade,
          cpf,
          nome,
          senha,
          status,
          tipo,
        });
      }, 120000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [agendamentosDaVezHoje, cpf, dataSchedulesNext, nome, page, searchParams, senha, status, tipo, tipo_prioridade]);

  return (
    <Box className={classes.main}>
      <Box
        style={{
          overflow: "auto",
        }}
      >
        <Paper className={classes.root}>
          <ModalPause />
          {callingPass && (
            <Alert color="warning">
              <Box>
                Aguarde{" "}
                <strong>
                  <Timer key={timerKey} startTimer={callingPass} />s
                </strong>
                , Para chamar novamente ou liberar a senha!
              </Box>
            </Alert>
          )}

          <Box className={classes.tableDatail}>Agendamento da vez </Box>
          <Box style={{ overflow: "auto", paddingBottom: 10 }}>
            <SchedulingFilter />

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>CIDADÃO/CPF</StyledTableCell>
                  <StyledTableCell align="left">DATA/HORA</StyledTableCell>
                  <StyledTableCell align="left">TIPO</StyledTableCell>
                  <StyledTableCell align="left">STATUS</StyledTableCell>
                  <StyledTableCell align="left">SERVIÇO</StyledTableCell>
                  <StyledTableCell align="left">
                    SENHA / PRIORIDADE
                  </StyledTableCell>

                  <StyledTableCell align="left">AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingSchedulesNext && (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {dataSchedulesNext &&
                  dataSchedulesNext.results.length > 0 &&
                  dataSchedulesNext.results.map((currentSchedule: Agendamento) => (
                    <TableRowCheckTime
                      data={currentSchedule.data}
                      hora={currentSchedule.hora}
                      status={currentSchedule.status}
                      key={`${currentSchedule.ticket}-key-${currentSchedule.cpf}`}
                    >
                      <StyledTableCell component="th" scope="row">
                        {currentSchedule?.user?.first_name ||
                          currentSchedule?.nome}
                        <Box>
                          <strong>
                            {cpfMask(
                              currentSchedule?.user?.username ||
                                currentSchedule.cpf
                            )}
                          </strong>
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {`${dateFormat(currentSchedule.data)} ${
                          currentSchedule.hora
                        }`}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.tipo}
                      </StyledTableCell>
                      <StyledTableCell className={classes.status} align="left">
                        {currentSchedule.status}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.servico.titulo}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.senha_formatada} (
                        {!currentSchedule?.prioridade &&
                        !currentSchedule.prioridade_alta
                          ? "Normal"
                          : currentSchedule.prioridade_alta
                          ? "Alta Prioridade"
                          : "Prioridade"}
                        )
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {currentSchedule.para_dependente &&
                          currentSchedule.dependentes && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Dependentes"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className={classes.buttonIcon}
                                  onClick={() => {
                                    setSchedulesDependentCurrent(
                                      currentSchedule
                                    );
                                    setOpenDependentsDialog(true);
                                  }}
                                  disabled={callingPass}
                                >
                                  <People />
                                </Button>
                              </span>
                            </Tooltip>
                          )}

                        {!currentSchedule.para_dependente && (
                          <Tooltip
                            title={`${
                              callingPass
                                ? `Ativação em ${remainingTime}s`
                                : "Sem dependentes"
                            }`}
                          >
                            <span>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonIcon}
                                disabled
                              >
                                <People />
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                        {(currentSchedule.status === "Aguardando" ||
                          currentSchedule.status === "Encaminhado" ||
                          currentSchedule.status === "Compareceu") &&
                          (currentSchedule.senha_formatada ||
                            currentSchedule.tipo === "Online") && (
                            <>
                              <Tooltip
                                title={`${
                                  callingPass
                                    ? `Ativação em ${remainingTime}s`
                                    : "Iniciar atendimento"
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.buttonIcon}
                                    onClick={() => {
                                      setCurrentSchedule(currentSchedule);
                                      setOpenAttendanceDialog(true);
                                    }}
                                    disabled={callingPass}
                                  >
                                    <PlayArrow />
                                  </Button>
                                </span>
                              </Tooltip>
                            </>
                          )}

                        {currentSchedule.status === "Em Andamento" &&
                          currentSchedule.tipo !== "Online" &&
                          !currentSchedule?.congelado && (
                            <>
                              <Tooltip
                                title={`${
                                  callingPass
                                    ? `Ativação em ${remainingTime}s`
                                    : "Finalizar atendimento"
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.buttonIcon}
                                    onClick={() => {
                                      setCurrentSchedule(currentSchedule);
                                      setOpenAttendanceStop(true);
                                    }}
                                    disabled={callingPass}
                                  >
                                    <CheckCircleOutline />
                                  </Button>
                                </span>
                              </Tooltip>
                            </>
                          )}
                        {currentSchedule.status === "Em Andamento" &&
                          currentSchedule.tipo === "Online" && (
                            <>
                              <Tooltip
                                title={`${
                                  callingPass
                                    ? `Ativação em ${remainingTime}s`
                                    : "Voltar para atendimento"
                                }`}
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.buttonIcon}
                                    onClick={() =>
                                      history.push(
                                        `/atendimento/${currentSchedule.id}`
                                      )
                                    }
                                    disabled={callingPass}
                                  >
                                    <ArrowRight />
                                  </Button>
                                </span>
                              </Tooltip>
                            </>
                          )}
                        <Tooltip
                          title={`${
                            callingPass
                              ? `Ativação em ${remainingTime}s`
                              : "Comprovante Agendamento"
                          }`}
                        >
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              component="a"
                              href={getHrefComprovante(currentSchedule.ticket)}
                              target="_blank"
                              className={classes.buttonIcon}
                              disabled={callingPass}
                            >
                              <Print />
                            </Button>
                          </span>
                        </Tooltip>
                        {(currentSchedule.status === "Compareceu" || currentSchedule.status === "Encaminhado") &&
                          currentSchedule.tipo !== "Online" &&
                          (!currentSchedule?.guiche ||
                            currentSchedule?.guiche?.id ===
                              currentTicketOffice?.id) && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Chamar Senha"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleCallPass(currentSchedule.id as string)
                                  }
                                  className={classes.buttonIcon}
                                  disabled={callingPass}
                                >
                                  <MdCampaign size="25" />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        {currentSchedule.status === "Compareceu" &&
                          currentSchedule.tipo !== "Online" &&
                          currentSchedule?.guiche?.id ===
                            currentTicketOffice?.id && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Liberar Senha!"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleUnlockPass(
                                      currentSchedule.id as string
                                    )
                                  }
                                  className={classes.buttonIcon}
                                  disabled={callingPass || callingUnlockPass}
                                >
                                  <LockOpen />
                                </Button>
                              </span>
                            </Tooltip>
                          )}

                        {currentSchedule.status === "Em Andamento" &&
                          currentSchedule.tipo !== "Online" &&
                          !currentSchedule?.congelado && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Congelar Atendimento"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    backgroundColor: "#90caf9",
                                    color: "rgb(255, 255, 255)",
                                  }}
                                  onClick={() =>
                                    handleOpenFreeze(currentSchedule)
                                  }
                                  className={classes.buttonIcon}
                                  disabled={callingPass || callingUnlockPass}
                                >
                                  <AlarmOff />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        {currentSchedule.status === "Em Andamento" && (
                          <Tooltip
                            title={`${
                              callingPass
                                ? `Ativação em ${remainingTime}s`
                                : "Nova demanda"
                            }`}
                          >
                            <span>
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  color: "rgb(255, 255, 255)",
                                }}
                                onClick={() =>
                                  handleOpen156(
                                    currentSchedule.protocolo as string,
                                    currentSchedule.user?.username ||
                                      (currentSchedule.cpf as string),
                                    currentSchedule.tipo as string
                                  )
                                }
                                className={classes.buttonIcon}
                                disabled={callingPass || callingUnlockPass}
                              >
                                <PermPhoneMsg />
                              </Button>
                            </span>
                          </Tooltip>
                        )}

                        {currentSchedule.status === "Em Andamento" &&
                          currentSchedule.habilitar_formflow &&
                          !currentSchedule.protocolo_formflow && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Novo Formulário"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    color: "rgb(255, 255, 255)",
                                  }}
                                  onClick={() =>
                                    handleOpenFormFlow(currentSchedule)
                                  }
                                  className={classes.buttonIcon}
                                  disabled={callingPass || callingUnlockPass}
                                >
                                  <MdNoteAdd size={20} />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                        {currentSchedule.status === "Em Andamento" && (
                          <Tooltip title={`Não compareceu`}>
                            <span>
                              <Button
                                variant="contained"
                                size="small"
                                className={classes.buttonIconAttention}
                                onClick={() => {
                                  setCurrentSchedule(currentSchedule);
                                  setOpenAttendanceCancel(true);
                                }}
                              >
                                <Icon className="material-icons-outlined">
                                  person_off
                                </Icon>
                              </Button>
                            </span>
                          </Tooltip>
                        )}

                        {currentSchedule.status === "Em Andamento" &&
                          currentSchedule.protocolo_formflow && (
                            <Tooltip
                              title={`${
                                callingPass
                                  ? `Ativação em ${remainingTime}s`
                                  : "Detalhes Solicitação"
                              }`}
                            >
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    color: "rgb(255, 255, 255)",
                                  }}
                                  onClick={() =>
                                    handleOpenFormFlowDetails(currentSchedule)
                                  }
                                  className={classes.buttonIcon}
                                  disabled={callingPass || callingUnlockPass}
                                >
                                  <Visibility />
                                </Button>
                              </span>
                            </Tooltip>
                          )}

                        {(currentSchedule.status === "Concluído" ||
                          currentSchedule.status === "Congelado" ||
                          currentSchedule.status === "Em Andamento") &&
                          currentSchedule.atendente_id?.length && (
                            <Tooltip title="Demandas do agendamento">
                              <span>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    handleOpen156Demanda(currentSchedule)
                                  }
                                  className={classes.buttonIcon}
                                >
                                  <ListAlt />
                                </Button>
                              </span>
                            </Tooltip>
                          )}
                      </StyledTableCell>
                    </TableRowCheckTime>
                  ))}
              </TableBody>
            </Table>
            <PaginationService
              getSearch={(page) => {
                setNewPage(page)
                addUrlSearchParams(page)
              }}
              listSearch={{
                count: dataSchedulesNext?.count,
                next: dataSchedulesNext?.next,
                previous: dataSchedulesNext?.previous,
                current: dataSchedulesNext?.current,
                total_pages: dataSchedulesNext?.total_pages,
              }}
            />
          </Box>
          <DependentsDialog
            setOpenDependentsDialog={setOpenDependentsDialog}
            openDependentsDialog={openDependentsDialog}
            schedule={schedulesDependentCurrent}
          />
          <AttendanceDialog
            setOpenAttendanceDialog={setOpenAttendanceDialog}
            openAttendanceDialog={openAttendanceDialog}
            schedule={currentSchedule}
          />
          <AttendanceDialogCancel
            setOpenAttendanceCancel={setOpenAttendanceCancel}
            openAttendanceCancel={openAttendanceCancel}
            schedule={currentSchedule}
          />
          <AttendanceDialogStop
            setOpenAttendanceStop={setOpenAttendanceStop}
            openAttendanceStop={openAttendanceStop}
            schedule={currentSchedule}
          />
        </Paper>
      </Box>
      <Typography className={classes.textSchedules}>
        Lista de agendamentos
      </Typography>

      <Box
        style={{
          overflow: "auto",
          background: "#FFF",
        }}
      >
        {matchesMobile ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
            style={{
              borderBottom: "1px solid #F7F8FC",
              borderRadius: 4,
              marginLeft: 24,
            }}
          >
            <Tab
              style={{ padding: 0, marginRight: 48 }}
              label={
                <span className={classes.tabLabel}>
                  {` Próximos agendamentos (
                  ${dataSchedulesToday?.count || 0}
                  )`}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  {`Agendamentos Anteriores (${dataSchedulesOlds?.count || 0})`}
                </span>
              }
              {...a11yProps(1)}
            />
            <Tab
              style={{ padding: 0 }}
              label={
                <span className={classes.tabLabel}>
                  {`Todos (${dataSchedulesAll?.count || 0})`}
                </span>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        ) : (
          <>
            <IconButton onClick={(e): void => handleClick(e, "menu")}>
              <Menu />
            </IconButton>
            {value === 0 &&
              ` Próximo agendamentos hoje (
                  ${dataSchedulesToday?.count || 0}
                  )`}
            {value === 1 &&
              `Agendamentos Anteriores (${dataSchedulesOlds?.count || 0})`}
            {value === 3 && `Todos (${dataSchedulesAll?.count || 0})`}
            <Popover
              open={Boolean(anchorElMenu)}
              anchorEl={anchorElMenu}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(0)}
                >
                  <Typography className={classes.textMenuItem}>
                    Próximo agendamentos hoje (
                    {dataSchedulesToday?.count >
                    currentTicketOffice?.user?.length
                      ? dataSchedulesToday.count -
                        (currentTicketOffice?.user?.length || 1)
                      : 0}
                    )
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(1)}
                >
                  <Typography className={classes.textMenuItem}>
                    Agendamentos Anteriores ({dataSchedulesOlds?.count || 0})
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleChangeIndex(2)}
                >
                  <Typography className={classes.textMenuItem}>
                    Todos ({dataSchedulesAll?.count || 0})
                  </Typography>
                </MenuItem>
              </Box>
            </Popover>
          </>
        )}
        <Box>
          {/* Hoje */}
          <TabAttendanceToday value={value} index={0} />
          {/* Anteriores */}
          <TabOldsAttendance value={value} index={1} />
          {/* Todos */}
          <TabAllAttendance value={value} index={2} />
        </Box>
      </Box>

      <Modal156
        open={open156}
        setOpen={setOpen156}
        protocol={protocolSelected}
        cpf={cpfSelected}
        tipo={typeSelected}
      />

      <Modal156ListDemand
        open={open156Demand}
        setOpen={setOpen156Demand}
        protocol={protocolSelected}
        cpf_atendente={cpfAtendenteSelected}
      />
      <ModalFormFlow
        open={openFormFlow}
        setOpen={setOpenFormFlow}
        protocol={protocolSelected}
        servico_slug={slugServicoSelected}
        cpf_cidadao={cpfSelected}
      />

      <ModalFormFlowDetails
        open={openFormFlowDetails}
        setOpen={setOpenFormFlowDetails}
        protocol={protocolSelected}
        protocol_form={protocolFormSelected}
      />

      {scheduleSelected && (
        <ModalFreeze
          open={openFreeze}
          setOpen={setOpenFreeze}
          agendamento={scheduleSelected}
        />
      )}
    </Box>
  );
}
