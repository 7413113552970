import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  containerQrCode: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media print': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  codText: {
    fontSize: 6,
    fontFamily: 'Roboto',
    fontWeight: 400,
    margin: '12px 0px',
  },
  infoFieldset: {
    margin: '12px 8px',
    border: '0.5px solid #373F41',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 18px',
  },
  title: {
    fontSize: 8,
    fontWeight: 500,
    fontFamily: 'Roboto',
    color: '#373F41',
    lineHeight: '12px',
    margin: '5px 0px',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontSize: 8,
    fontWeight: 400,
    lineHeight: '12px',
  },
  dateInfoFieldset: {
    margin: '12px 8px',
    border: '0.5px solid #373F41',
    borderRadius: 4,
    padding: '8px 12px',
  },
  dateInfoText: {
    fontFamily: 'Roboto',
    fontSize: 6,
    textTransform: 'uppercase',
    color: '#373F41',
    fontWeight: 500,
    textAlign: 'center',
  },
  buttons: {
    margin: '8px 5px',
    color: '#ffff',
    '@media print': {
      display: 'none',
    },
  },
}));

export default useStyles;
