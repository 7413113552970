import React, { useState, useEffect } from 'react';

interface TimerProps {
  startTimer: boolean;
}

const Timer: React.FC<TimerProps> = ({ startTimer }) => {
  const [seconds, setSeconds] = useState(25);

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [startTimer, seconds]);

  return (
    <>{seconds}</>
  );
};

export default Timer;