import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    background: "#053396",
    alignItems: 'center',


  },
  iconsMenu: {
    borderRadius: 4, 
    padding: '10px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  listMenu: {
    marginTop: 40,
  }
}));

export default useStyles;