import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider,
  Link,
  CircularProgress,
  useMediaQuery,
  FormLabel,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Atendimento } from "../../../../interfaces/atendimento";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import { useStyles } from "./style";
import { updateStatusAgendamento } from "../../../../services/agendamento";

interface Props {
  openAttendanceDialog: boolean;
  setOpenAttendanceDialog: (openAttendanceDialog: boolean) => void;
  attendence: undefined | Atendimento;
}

export default function AttendanceDialog({
  setOpenAttendanceDialog,
  openAttendanceDialog,
  attendence,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles();
  const history = useHistory();
  const matchesMobile = useMediaQuery("(min-width:576px)");

  const handleInit = async (attendenceId: number | string) => {
    const token: string | null = sessionStorage.getItem(
      "gov_access_token_sso"
    );
    const guiche = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;

    try {
      setLoading(true);
      if (token) {
        const { data } = await updateStatusAgendamento(
          token,
          attendenceId as number,
          "Em Andamento",
          guiche
        );
        if (data) {
          history.go(0);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setErrorMessage(error.response?.data?.detail);
      }
      console.log("Erro ao iniciar o atendimento", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenAttendanceDialog(false);
  };

  const returnErrorMessage = useMemo(() => (typeof errorMessage) === 'string'
    , [errorMessage]);

  return (
    <Dialog
      fullWidth
      open={openAttendanceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja iniciar o atendimento?
        </Typography>
      </DialogTitle>
      {attendence && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {attendence.user.first_name}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(attendence.user.username)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {attendence.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(attendence.data)} às ${attendence.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {attendence.status}
            </Typography>
          </Box>

          <Divider light />
          {returnErrorMessage && (
            <FormLabel className={classes.errorText} error>
              {errorMessage}
            </FormLabel>
          )}
          <div className={classes.buttonCloseModal}>
            {!matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 12, width: "100%" }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleInit(attendence.id)}
                disabled={loading}
              >
                {!loading ? "Iniciar atendimento" : <CircularProgress />}
              </Button>
            )}
            {!loading && (
              <Link
                className={classes.link}
                onClick={handleClose}
                style={
                  !matchesMobile
                    ? { width: "100%", textAlign: "center", padding: "15px 0" }
                    : {}
                }
              >
                fechar
              </Link>
            )}

            {matchesMobile && !errorMessage && (
              <Button
                style={{ color: "#FFF", fontSize: 12 }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleInit(attendence.id)}
                disabled={loading || returnErrorMessage}
              >
                {!loading ? "Iniciar atendimento" : <CircularProgress />}
              </Button>
            )}

            {returnErrorMessage && (
              <Button
                style={{ color: "#FFF", fontSize: 12 }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => history.push('/agendamento')}
              >
                Retornar para o início
              </Button>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
