import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";
import useStyles from "./style";
import { useSchedules } from "../../useSchedules";
import { Agendamento } from "../../../../interfaces/agendamento";
import { cpfMask } from "../../../../utils/cpfFormat";


type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  agendamento: Agendamento;
};

export default function ModalUnfreeze({
  open,
  setOpen,
  agendamento,
}: Props) {
  const classes = useStyles();

  const { handlePlay } = useSchedules();


  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  }


  const handleSave = async () => {

    setLoading(true);
    await handlePlay(
      agendamento.id as string,
    );
    handleClose();
  }

  return (<>
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>

          Descongelar atendimento
        </Typography>
      </DialogTitle>
      <DialogContent>

          <Box mb={3}>
            <Typography className={classes.textModalTitle}>Nome</Typography>
            <Typography className={classes.textModal}>
              {agendamento.nome}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>CPF</Typography>
            <Typography className={classes.textModal}>
              {cpfMask(agendamento.cpf || '')}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {agendamento.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>Motivo congelamento</Typography>
            <Typography className={classes.textModal}>
              {agendamento.congelado_motivo || 'Não informado'}
            </Typography>
          </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          color="primary" variant="contained" type="submit" form="form-pause"
          disabled={loading}
          onClick={handleSave}
        >
          {loading ? <CircularProgress /> : 'Descongelar'}
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}