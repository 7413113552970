import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    textModalTitleUDependent: {
        color: "#373F41",
        fontFamily: "Mulish",
        fontWeight: "bold",
        fontSize: 18,
        letterSpacing: 0.25,
    },
    textModalTitleUDependentCPF: {
        color: "rgba(55, 63, 65, 0.6)",
        fontFamily: "Mulish",
        fontWeight: 600,
        fontSize: 16,
    },
    textHeaderModal: {
        textTransform: "uppercase",
        fontFamily: "Mulish",
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: 0.3,
        width: '98%',
    },
    header: {
        background: "#FFF",
    },
    buttonClose: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(0.5),
        color: "#B7B7B7",
    },
    textModal: {
        color: "#373F41",
        fontFamily: "Mulish",
        fontWeight: 600,
        fontSize: 16,
    },
    titleDependents: {
        display: "inline-grid",
        verticalAlign: "middle",
        margin: "15px 20px 29px",
    },
    textModalTitle: {
        fontSize: 14,
        color: "rgba(55, 63, 65, 0.6)",
        textTransform: "uppercase",
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: "bold",
    },
    buttonCloseModal: {
        display: "flex",
        justifyContent: "space-between",
        padding: "32px 0px 20px",
        alignItems: "center",
        flexWrap: 'wrap',
    },
    link: {
        color: "#333333",
        cursor: "pointer",
        fontSize: 15,
        fontFamily: "Mulish",
        fontWeight: "bold",
        textTransform: "uppercase",
        textDecorationLine: "none",
        letterSpacing: 0.3,
        fontStyle: "normal",
        marginLeft: 16,

    },
    boxRowPriority: {
        display: 'flex',
        marginBottom: 15,
        marginTop: 15,
    },

    boxDivide: {
        borderBottom: '1px solid rgba(51, 51, 51, 0.12);',
        width: '100%',
        margin: '20px 0px',
    },
    boxContentPriority: {


    },
    textTitlePriority: {
        fontSize: 16,
        color: '#333',
        fontWeight: 500,
        fontFamily: 'Roboto',
    },
    textSubTitlePriority: {
        fontSize: 16,
        color: 'rgba(51, 51, 51, 0.72)',
        fontWeight: 400,
        fontFamily: 'Roboto',
        marginTop: 8,
    }
}));

export default useStyles;
