import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputMask from "react-input-mask";
import { useFormFilterAll } from "./useFormFilterAll";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { format, isValid } from "date-fns";
import { MdSearchOff } from "react-icons/md";
import { Search } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { getUTCDate } from "../../utils/dateFormat";


type FormFilterAllProps = {
  updateDataListFunction: (params?: any) => Promise<void>;
  clearDataListFunction: () => Promise<void>;
};

export function FormFilterAll({ updateDataListFunction, clearDataListFunction }: FormFilterAllProps) {
  const {
    matchesMobile,
    classes,
    valueFilterDate,
    setValueFilterDate,
    selectedDate,
    handleDateChange,
    selectedDateInit,
    handleDateChangeInit,
    selectedDateEnd,
    handleDateChangeEnd,
    valueFilterHora,
    setValueFilterHora,
    serviceSelected,
    setServiceSelected,
    servicesData,
    valueFilterCpf,
    setValueFilterCpf,
    valueFilterStatus,
    setValueFilterStatus,
    valueFilterType,
    setValueFilterType,
  } = useFormFilterAll();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (servicesData) {
      const filterDate = searchParams.get('data_tipo')
      const data = getUTCDate(searchParams.get('data')!);
      const dateInit = getUTCDate(searchParams.get('data_inicio')!);
      const dateEnd = getUTCDate(searchParams.get('data_fim')!);
      const hora = searchParams.get('hora')
      const servico = servicesData.find((service) => service.id === +searchParams.get('servico')!);
      const status = searchParams.get('status')
      const tipo = searchParams.get('tipo')
      const cpf = searchParams.get('cpf')

      filterDate && setValueFilterDate(filterDate)
      dateInit && handleDateChangeInit(dateInit)
      dateEnd && handleDateChangeEnd(dateEnd)
      data && handleDateChange(data)
      hora && setValueFilterHora(hora)
      servico && setServiceSelected(servico)
      cpf && setValueFilterCpf(cpf)
      status && setValueFilterStatus(status)
      tipo && setValueFilterType(tipo)
    }

  }, [servicesData])


  const handleUpdateList = async () => {

    let params: any = {};

    params["data_tipo"] = valueFilterDate;

    if (valueFilterDate === "Data") {
      if (selectedDate) {
        params["data"] = format(selectedDate, "yyyy-MM-dd");
      }
    }
    if (valueFilterDate === "Período") {
      if (selectedDateInit) {
        params["data_inicio"] = format(selectedDateInit, "yyyy-MM-dd");
        params["data_fim"] = format(selectedDateInit, "yyyy-MM-dd");
      }
      if (selectedDateEnd) {
        params["data_fim"] = format(selectedDateEnd, "yyyy-MM-dd");
      }
    }

    if (valueFilterHora) {
      params["hora"] = valueFilterHora;
    }

    if (serviceSelected?.id) {

      params["servico"] = serviceSelected?.id;
    }

    if (valueFilterType) {
      params["tipo"] = valueFilterType;
    }

    if (valueFilterCpf && valueFilterCpf.replace(/[_.-]/g, "")) {
      params["cpf"] = valueFilterCpf.replace(/[_.-]/g, "");
    }
    if (valueFilterStatus) {
      params["status"] = valueFilterStatus;
    }
    await updateDataListFunction(params);
  };


  const handleClearFilter = async () => {
    handleDateChange(null);
    setValueFilterCpf('');
    setValueFilterStatus('');
    setValueFilterHora('');
    setServiceSelected(undefined);
    handleDateChangeInit(null)
    handleDateChangeEnd(null);
    setValueFilterType('');
    await clearDataListFunction();
  }

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {

    setValueFilterStatus(event?.target?.value as string);

  }

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {

    setValueFilterType(event?.target?.value as string);

  }

  return (

    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      flexWrap="wrap"
    >
      <>
        <Autocomplete
          id="controllable-states-data"
          value={valueFilterDate}
          options={[
            "Data",
            "Período",
          ]}
          onChange={(event: any, newValue: string | null) => {
            handleDateChange(null)
            handleDateChangeEnd(null)
            handleDateChangeInit(null)
            setValueFilterDate(newValue as string);
          }}
          style={
            !matchesMobile
              ? { width: "100%", marginBottom: 20 }
              : { marginRight: 20, minWidth: 235, marginBottom: 20 }
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filtrar data por"
            />
          )}
        />
        {valueFilterDate === "Data" && <MuiPickersUtilsProvider
          locale={ptBR}
          utils={DateFnsUtils}
        >
          <KeyboardDatePicker
            disableFuture={false}
            minDateMessage=""
            invalidDateMessage="A data informada é inválida"
            InputProps={{
              className: classes.menuItem,
              classes: { adornedEnd: classes.adornedEnd },
            }}
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Data"
            format="dd/MM/yyyy"
            value={selectedDate}
            InputAdornmentProps={{
              position: "end",
              color: "#000",
            }}
            style={
              !matchesMobile
                ? { width: "100%", marginBottom: 20 }
                : { marginRight: 20, marginBottom: 14, }
            }
            onChange={(date) => {
              if (date && isValid(date)) {
                handleDateChange(date);
              } else {
                handleDateChange(null);
              }
            }}
          />
        </MuiPickersUtilsProvider>}


        {valueFilterDate === "Período" && <>
          <MuiPickersUtilsProvider
            locale={ptBR}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              disableFuture={false}
              minDateMessage=""
              invalidDateMessage="A data informada é inválida"
              InputProps={{
                className: classes.menuItem,
                classes: { adornedEnd: classes.adornedEnd },
              }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Data Início"
              format="dd/MM/yyyy"
              value={selectedDateInit}
              InputAdornmentProps={{
                position: "end",
                color: "#000",
              }}
              style={
                !matchesMobile
                  ? { width: "100%", marginBottom: 20 }
                  : { marginRight: 20, marginBottom: 20 }
              }
              onChange={(date) => {
                if (date && isValid(date)) {
                  handleDateChangeInit(date);
                } else {
                  handleDateChangeInit(null);
                }
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider
            locale={ptBR}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              disableFuture={false}
              minDateMessage=""
              invalidDateMessage="A data informada é inválida"
              InputProps={{
                className: classes.menuItem,
                classes: { adornedEnd: classes.adornedEnd },
              }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Data Fim"
              format="dd/MM/yyyy"
              value={selectedDateEnd}
              InputAdornmentProps={{
                position: "end",
                color: "#000",
              }}
              style={
                !matchesMobile
                  ? { width: "100%", marginBottom: 20 }
                  : { marginRight: 20, marginBottom: 20 }
              }
              onChange={(date) => {
                if (date && isValid(date)) {
                  handleDateChangeEnd(date);
                } else {
                  handleDateChangeEnd(null);
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </>}

        <TextField
          value={valueFilterHora}
          onChange={(event: any): void => {
            if (event?.target?.value) {
              setValueFilterHora(event.target.value);
            }
          }
          }
          type="time"
          label="Hora"
          style={
            !matchesMobile
              ? { width: "100%", marginBottom: 20 }
              : { marginRight: 20, marginBottom: 20 }
          }
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />

        <FormControl
          variant="outlined"
          style={
            !matchesMobile
              ? { minWidth: "100%", marginBottom: 20 }
              : { marginRight: 20, minWidth: 400, marginBottom: 20 }
          }
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Selecione o serviço
          </InputLabel >
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={serviceSelected?.id ?? ''}
          >
            <MenuItem
              onClick={() => {
                setServiceSelected(undefined);
              }}
              value={undefined}

            >
              Selecione serviço
            </MenuItem>
            {servicesData?.map((service) => (
              <MenuItem
                onClick={() => {
                  setServiceSelected(service);
                }}
                value={service.id}
                key={service.slug}
              >
                {service.titulo}
              </MenuItem>
            ))}
          </Select>

        </FormControl>



        <InputMask
          value={valueFilterCpf || ""}
          onChange={(event: any): void => {
            if (event?.target?.value) {
              setValueFilterCpf(event.target.value);
            }
          }}
          mask={"999.999.999-99"}
        >
          {() => (
            <TextField
              placeholder="Ex: 000.000.000-00"
              label="CPF"
              style={
                !matchesMobile
                  ? { width: "100%", marginBottom: 20 }
                  : { marginRight: 20, marginBottom: 20, width: 300 }
              }
              variant="outlined"
            />
          )}
        </InputMask>

        <FormControl
          variant="outlined"
          style={
            !matchesMobile
              ? { minWidth: "100%", marginBottom: 20 }
              : { marginRight: 20, minWidth: 235, marginBottom: 20 }
          }
        >
          <InputLabel id="select-status-label">
            Status
          </InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status-id"
            value={valueFilterStatus || ''}
            onChange={handleChangeStatus}
          >
            <MenuItem
              value={''}

            >
              Status
            </MenuItem>
            {[
              '',
              "Aguardando",
              "Em Andamento",
              "Concluído",
              "Cancelado",
              "Não Compareceu",
              "Reagendou",
              "Compareceu",
              "Congelado",
            ].map((status, index) => (
              <MenuItem
                value={status}
                key={`${status}-${index}`}
              >
                {status}
              </MenuItem>
            ))}
          </Select>

        </FormControl>

        <FormControl
          variant="outlined"
          style={
            !matchesMobile
              ? { minWidth: "100%", marginBottom: 20 }
              : { marginRight: 20, minWidth: 235, marginBottom: 20 }
          }
        >
          <InputLabel id="select-tipo-label">
            Tipo
          </InputLabel>
          <Select
            labelId="select-tipo-label"
            id="select-tipo-id"
            value={valueFilterType || ''}
            onChange={handleChangeType}
          >
            <MenuItem
              value={''}
            >
              Tipo
            </MenuItem>
            {[
              "Presencial",
              "Online",
              "Espontâneo"
            ].map((status, index) => (
              <MenuItem
                value={status}
                key={`${status}-${index}`}
              >
                {status}
              </MenuItem>
            ))}
          </Select>

        </FormControl>

      </>
      {(selectedDate ||
        valueFilterCpf ||
        valueFilterStatus ||
        valueFilterHora ||
        serviceSelected?.id ||
        selectedDateInit ||
        selectedDateEnd ||
        valueFilterType
      ) && (
          <Button
            variant="outlined"
            size="large"
            className={classes.buttonCancelSearch}
            style={!matchesMobile ? { width: "100%", margin: 0, marginBottom: 20 } : { marginBottom: 20 }}
            startIcon={<MdSearchOff />}
            onClick={handleClearFilter}
          >
            LIMPAR
          </Button>
        )}
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.buttonSearch}
        style={!matchesMobile ? { width: "100%", margin: 0 } : { marginBottom: 20 }}
        startIcon={<Search />}
        onClick={handleUpdateList}

      >
        BUSCAR
      </Button>
    </Box>
  );

}

export default FormFilterAll;
