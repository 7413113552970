import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    minHeight: 400,
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  iconAvatar: {
    width: 60,
    height: 60,
    margin: '0px auto',
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    fontSize: 18,
    color: "#373F41",
    textTransform: "capitalize",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  cpf: {
    color: 'rgba(55, 63, 65, 0.6)',
    fontWeight: 600,
    fontFamily: "Mulish",
  },
  textTitle: {
    fontSize: 14,
    fontFamily: "Mulish",
    textTransform: "uppercase",
    color: 'rgba(55, 63, 65, 0.6)',
    marginTop: 25
  },
  textSubTitle: {
    fontSize: 16,
    color: '#373F41',
    fontWeight: 600,
    fontFamily: 'Mulish',
    fontStyle: "normal",
  },
  buttonAttendance: {
    maxWidth: 335,
    width: '100%',
    height: 48,
    fontSize: 14,
    fontFamily: 'Mulish',
    textTransform: 'uppercase',
    marginTop: 16,
    borderRadius: 4
  },

  video: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  chat: {
    padding: theme.spacing(2),
  },
  buttonCloseModal: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px 0px 20px",
    alignItems: "center"
  },
  link: {
    color: "#373F41",
    cursor: "pointer",
    fontSize: 14,
    fontFamily: "Mulish",
    fontWeight: "bold",
    textTransform: "uppercase",
    textDecorationLine: "underline",
    letterSpacing: 0.3,
    fontStyle: "normal",
    marginLeft: 16,
  },
}));

export default useStyles;