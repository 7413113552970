import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Radio, TextField, Typography } from "@material-ui/core";
import { Close, Pause } from "@material-ui/icons";
import { FormEvent, useState } from "react";
import useStyles from "./style";
import { useStateValue } from "../../../../providers/StateProvider";
import { pausarGuiche } from "../../../../services/agendamento";
import toastMessage from "../../../../utils/handleToastMessage";
import { useHistory } from "react-router-dom";




export default function ModalPause() {
  const classes = useStyles();
  const [{ currentTicketOffice }] = useStateValue();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [observacao, setObservacao] = useState('');
  const history = useHistory();
  const [motivoSelected, setMotivoSelected] = useState<string>('');

  const handleToggle = async () => {
    setOpen(!open);
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
       const token: string | null = sessionStorage.getItem(
      "gov_access_token_sso"
    );
      const data = await pausarGuiche(
        token || '',
        currentTicketOffice?.id,
        motivo,
        observacao,
      );

      if (data.status === 200) {

        setTimeout(() => {
          toastMessage({
            type: "success",
            message: "Pausado com Sucesso!",
          });

          currentTicketOffice.pausado = true;
          sessionStorage.setItem("gov_ticket_office", JSON.stringify(currentTicketOffice));
          history.push('/agendamento/pause');
          setLoading(false);
        }, 3000);


      } else {
        setLoading(false);
        toastMessage({
          type: "error",
          message: "Error ao pausar guichê!",
        });
      }



    } catch (error: any) {
      toastMessage({
        type: "error",
        message: error?.response?.data?.detail || "Error ao pausar guichê!",
      });
      setLoading(false);

    }
  }

  return (<>
    <Box style={{
      display: 'flex',
      justifyContent: 'end',
      marginBottom: 20,
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
    }}>
      <Button onClick={handleToggle} color="primary" variant="contained">
        <Pause />
        Pausar Guichê
      </Button>
    </Box>


    <Dialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <IconButton onClick={handleToggle} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Pausar Guichê
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box component="form" id="form-pause" onSubmit={handleSave}>

          <Box className={classes.containerIdentificationType}
            onClick={() =>{
              setMotivoSelected('administrativo');
              setMotivo('Parar guichê para resolução administrativa')
            }}
            >
            <Box className={classes.sectionTitleIdentification}>
              <Box className={classes.containerTitleIdentification}>
                <Radio
                  required
                  value='Parar guichê para resolução administrativa'
                  name='user'
                  color="primary"
                  onChange={() =>{
                    setMotivoSelected('administrativo');
                    setMotivo('Parar guichê para resolução administrativa')
                  }}
                  checked={motivoSelected === 'administrativo'}
                />
                <Box className={classes.boxTitleIcon}>
                  <Typography className={classes.titleCard}>Parar guichê para resolução administrativa</Typography>
                </Box>

              </Box>
            </Box>
          </Box>

          <Box className={classes.containerIdentificationType}
            onClick={() => {
              setMotivoSelected('banheiro');
              setMotivo('Ir ao banheiro');
            }}
          >
            <Box className={classes.sectionTitleIdentification}>
              <Box className={classes.containerTitleIdentification}>
                <Radio
                  required
                  value='Ir ao banheiro'
                  name='user'
                  color="primary"
                  onChange={() => {
                    setMotivoSelected('banheiro');
                    setMotivo('Ir ao banheiro');
                  }}
                  checked={motivoSelected === 'banheiro'}
                />
                <Box className={classes.boxTitleIcon}>
                  <Typography className={classes.titleCard}> Ir ao banheiro</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.containerIdentificationType}
            onClick={() => {
              setMotivoSelected('intervalo');
              setMotivo('Intervalo');
            }}
          >
            <Box className={classes.sectionTitleIdentification}>
              <Box className={classes.containerTitleIdentification}>
                <Radio
                  required
                  value='Intervalo'
                  name='user'
                  color="primary"
                  onChange={() => {
                    setMotivoSelected('intervalo');
                    setMotivo('Intervalo');
                  }}
                  checked={motivoSelected === 'intervalo'}
                />
                <Box className={classes.boxTitleIcon}>
                  <Typography className={classes.titleCard}>Intervalo</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.containerIdentificationType}
            onClick={() => {
              setMotivoSelected('almoco');
              setMotivo('Almoço');
            }}
          >
            <Box className={classes.sectionTitleIdentification}>
              <Box className={classes.containerTitleIdentification}>
                <Radio
                  required
                  value='Almoço'
                  name='user'
                  color="primary"
                  onChange={() => {
                    setMotivoSelected('almoco');
                    setMotivo('Almoço');
                  }}
                  checked={motivoSelected === 'almoco'}
                />
                <Box className={classes.boxTitleIcon}>
                  <Typography className={classes.titleCard}>Almoço</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.containerIdentificationType}
            onClick={() => {
              setMotivoSelected('outro');
              setMotivo('');
            }}
          >
            <Box className={classes.sectionTitleIdentification}>
              <Box className={classes.containerTitleIdentification}>
                <Radio
                  required
                  value='outro'
                  name='user'
                  color="primary"
                  onClick={() => {
                    setMotivoSelected('outro');
                    setMotivo('');
                  }}
                  checked={motivoSelected === 'outro'}
                />
                <Box className={classes.boxTitleIcon}>
                  <Typography className={classes.titleCard}>Outro</Typography>
                </Box>
              </Box>

            </Box>
            {motivoSelected === "outro" && (
            <Box className={classes.boxOuter}>
              <TextField
                type="text"
                label="Digite o motivo abaixo"
                required
                fullWidth
                variant="outlined"
                name="motivo"
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
                helperText="Mínimo 4 caracteres"
              />
            </Box>)}
          </Box>
          <TextField
            type="text"
            label="Observações (opcional)"

            fullWidth
            multiline
            minRows={3}
            variant="outlined"
            name="observacao"
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggle} color="primary">
          Cancelar
        </Button>
        <Button
          color="primary" variant="contained" type="submit" form="form-pause"
          disabled={loading || motivo.length < 4}
        >
          {loading ? <CircularProgress /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}