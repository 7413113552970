import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider,
  Link,
  TextField,
  CircularProgress,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useState } from "react";
import { MdHelp } from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  addObservation,
  updateStatusAgendamento,
} from "../../../../services/agendamento";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import useStyles from "./style";
import { Atendimento } from "../../../../interfaces/atendimento";

interface Props {
  openAttendanceCancel: boolean;
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void;
  schedule: undefined | Atendimento;
}

export default function AttendanceDialogCancel({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [observation, setObservation] = useState(undefined);
  const history = useHistory();
  const matchesMobile = useMediaQuery("(min-width:576px)");

  const handleClose = () => {
    setOpenAttendanceCancel(false);
  };

  const handleFinishAttendance = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;

      setLoading(true);
      if (token) {
        const { status } = await updateStatusAgendamento(
          token,
          scheduleId,
          "Não Compareceu",
          guiche,
        );
        if (status === 200 && !!observation) {
          await addObservation(token, scheduleId, observation || "");
        }
        history.push('/agendamento');
      }
    } catch (error) {
      console.log("Erro", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja informar o não comparecimento do cidadão?
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule.user.first_name}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.user.username)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              observações: (opcional)
              <Tooltip title="As informações deste campo serão compartilhadas no histórico do cidadão.">
                <span>
                  <IconButton disabled>
                    <MdHelp />
                  </IconButton>
                </span>
              </Tooltip>
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              disabled={loading}
              inputProps={{
                style: { fontSize: 14, fontFamily: "Mulish", fontWeight: 400 },
                placeholder: "As informações deste campo serão compartilhadas no histórico do cidadão.",
              }}
              style={{ width: "100%", marginBottom: 10 }}
              value={observation}
              onChange={(e: any) => {
                setObservation(e?.target?.value || "");
              }}
            />
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              style={{ color: "#FFF", fontSize: 12, flex: 0.5 }}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleFinishAttendance(schedule.id)}
              disabled={loading}
            >
              {!loading ? "CONFIRMAR" : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
