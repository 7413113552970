import { Box, useMediaQuery, Button, TextField } from "@material-ui/core";
import { useSchedules } from "../../useSchedules";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { MdSearchOff } from "react-icons/md";
import { cpfMask, removeCpfMask } from "../../../../utils/cpfFormat";

const SchedulingFilter = () => {
  const {
    classes,
    valueFilterName,
    setValueFilterName,
    valueFilterCpf,
    setValueFilterCpf,
    valueFilterPassword,
    setValueFilterPassword,
    valueFilterType,
    setValueFilterType,
    agendamentosDaVezHoje,
    valueFilterStatus,
    setValueFilterStatus,
    valueFilterTypeSchedule,
    setValueFilterTypeSchedule,
    currentPage,
    setCurrentPage
  } = useSchedules();
  const matchesMobile = useMediaQuery("(min-width:576px)");

  const handleClearFilter = () => {
    setValueFilterName("");
    setValueFilterCpf("");
    setValueFilterPassword("");
    setValueFilterType(null);
    setValueFilterStatus(null);
    setValueFilterTypeSchedule(null);
    setCurrentPage(1);
    window.history.pushState({}, "", '/agendamento');
    agendamentosDaVezHoje();
  };

  const handleSearch = () => {
    const updateSearchParams = (
      searchParams: URLSearchParams,
      key: string,
      value: string
    ) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    };

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const filters = {
      tipo_prioridade: valueFilterType,
      status: valueFilterStatus,
      tipo: valueFilterTypeSchedule,
      nome: valueFilterName,
      cpf: removeCpfMask(valueFilterCpf!),
      senha: valueFilterPassword,
      page: currentPage,
    };

    Object.entries(filters).forEach(([key, value]) => {
      updateSearchParams(searchParams, key, value as string);
    });

    const queryString = searchParams.toString();
    const newUrl = queryString
      ? `${window.location.pathname}?${queryString}`
      : window.location.pathname;

    window.history.pushState({}, "", newUrl);

    agendamentosDaVezHoje({
      tipo_prioridade: valueFilterType || undefined,
      cpf: valueFilterCpf ? removeCpfMask(valueFilterCpf) : undefined,
      nome: valueFilterName === "" ? undefined : valueFilterName,
      senha: valueFilterPassword === "" ? undefined : valueFilterPassword,
      status: valueFilterStatus || undefined,
      tipo: valueFilterTypeSchedule || undefined,
      page: currentPage || 1
    });
  };

  return (
    <Box className={classes.boxFilter}>
      <Autocomplete
        id="controllable-states-data"
        value={
          valueFilterType === "PrioridadeAlta"
            ? "Prioridade Alta"
            : valueFilterType
        }
        options={["Prioridade Alta", "Prioridade", "Normal"]}
        onChange={(event: any, newValue: string | null) => {
          const mapping: { [key: string]: string } = {
            "Prioridade Alta": "PrioridadeAlta",
            Prioridade: "Prioridade",
            Normal: "Normal",
          };
          setValueFilterType(
            newValue
              ? (mapping[newValue] as
                  | "PrioridadeAlta"
                  | "Prioridade"
                  | "Normal"
                  | null)
              : null
          );
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { minWidth: 235, marginBottom: 20 }
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tipo de prioridade"
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
          />
        )}
      />

      <Autocomplete
        id="controllable-states-data"
        value={valueFilterStatus}
        options={["Em Andamento", "Compareceu", "Aguardando"]}
        onChange={(event: any, newValue: string | null) => {
          setValueFilterStatus(
            newValue as "Em Andamento" | "Compareceu" | undefined
          );
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { minWidth: 235, marginBottom: 20 }
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Status"
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
          />
        )}
      />

      <Autocomplete
        id="controllable-states-data"
        value={valueFilterTypeSchedule}
        options={["Online", "Presencial", "Espontâneo"]}
        onChange={(event: any, newValue: string | null) => {
          setValueFilterTypeSchedule(
            newValue as "Online" | "Presencial" | "Espontâneo" | undefined
          );
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { minWidth: 235, marginBottom: 20 }
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tipo de agendamento"
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
          />
        )}
      />

      <TextField
        placeholder="Ex: João"
        label="Nome"
        value={valueFilterName}
        onChange={(event: any): void => {
          if (
            event?.target?.value !== null &&
            event?.target?.value !== undefined
          ) {
            setValueFilterName(event.target.value);
          }
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { marginBottom: 20, width: 300 }
        }
        variant="outlined"
      />

      <TextField
        placeholder="Ex: 000.000.000-00"
        label="CPF"
        value={valueFilterCpf}
        onChange={(event: any): void => {
          if (
            event?.target?.value !== null &&
            event?.target?.value !== undefined
          ) {
            setValueFilterCpf(cpfMask(event.target.value));
          }
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { marginBottom: 20, width: 300 }
        }
        variant="outlined"
      />

      <TextField
        placeholder="Ex: PA0000-A"
        label="Senha"
        value={valueFilterPassword}
        onChange={(event: any): void => {
          if (
            event?.target?.value !== null &&
            event?.target?.value !== undefined
          ) {
            setValueFilterPassword(event.target.value);
          }
        }}
        style={
          !matchesMobile
            ? { width: "100%", marginBottom: 20 }
            : { marginBottom: 20, width: 300 }
        }
        variant="outlined"
      />

      {(valueFilterName ||
        valueFilterCpf ||
        valueFilterType ||
        valueFilterStatus ||
        valueFilterTypeSchedule ||
        valueFilterPassword) && (
        <Button
          variant="outlined"
          size="large"
          className={classes.buttonCancelSearch}
          style={
            !matchesMobile
              ? { width: "100%", margin: 0, marginBottom: 20 }
              : { marginBottom: 20 }
          }
          startIcon={<MdSearchOff />}
          onClick={handleClearFilter}
        >
          LIMPAR
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.buttonSearch}
        style={
          !matchesMobile ? { width: "100%", margin: 0 } : { marginBottom: 20 }
        }
        startIcon={<Search />}
        onClick={() => handleSearch()}
      >
        BUSCAR
      </Button>
    </Box>
  );
};

export default SchedulingFilter;
