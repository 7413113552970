import {  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {useState } from "react";
import useStyles from "./style";
import { useSchedules } from "../../useSchedules";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  protocol: string;
  servico_slug: string;
  cpf_cidadao: string;
};

export default function ModalFormFlow({
  open,
  setOpen,
  protocol,
  servico_slug,
  cpf_cidadao,
}: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const handleToggle = async () => {
    setOpen(!open);
    setLoading(true);
    agendamentosDaVezHoje();
  };



  const { agendamentosDaVezHoje } = useSchedules();

  const token = sessionStorage.getItem('gov_access_token_sso');
  const baseUrl = `${process.env.REACT_APP_FORMFLOW_FRONT_URL}/carregar-formularios`;
  //const baseUrl = `http://localhost:3001/carregar-formularios`;

  const handleLoad = () => {
    setLoading(false);
  };

  return (<>
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <IconButton onClick={handleToggle} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
        Nova Demanda
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading  &&
          <Box style={{
            display: 'flex',
            width: '100%',
            height: '600',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress /></Box>
        }
        <iframe
          src={`${baseUrl}/?slug_servico=${servico_slug}&protocolo_atendimento=${protocol}&cpf=${cpf_cidadao}&cpf_cidadao=${cpf_cidadao}&token=${token}`}
          width="99%"
          height="99%"
          onLoad={handleLoad}
          style={{ display: loading ? 'none' : 'block' }}
          title="Novo Atendimento"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggle} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}