import {  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {useState } from "react";
import useStyles from "./style";
import { useStateValue } from "../../../../providers/StateProvider";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  protocol: string;
  cpf: string;
  tipo: string;
};

export default function Modal156({
  open,
  setOpen,
  protocol,
  cpf,
  tipo,
}: Props) {
  const classes = useStyles();
  const [{ currentTicketOffice }] = useStateValue();
  const [loading, setLoading] = useState(true);
  const handleToggle = async () => {
    setOpen(!open);
    setLoading(true);

  };

  const token = sessionStorage.getItem('gov_access_token_sso');
  const baseUrl = `${process.env.REACT_APP_156_URL}/demanda-guiche`;
  const local_atendimento = currentTicketOffice?.setor?.unidade?.nome || currentTicketOffice?.unidade?.nome;

  const handleLoad = () => {
    setLoading(false);
  };

  return (<>
    <Dialog
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <IconButton onClick={handleToggle} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
        Nova Demanda
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading  &&
          <Box style={{
            display: 'flex',
            width: '100%',
            height: '600',
            justifyContent: 'center',
            alignItems: 'center',

          }}>
            <CircularProgress /></Box>
        }
        <iframe
          src={`${baseUrl}?protocolo=${protocol}&token=${token}&cpf=${cpf}&canal=${tipo === "Online" ? "Chat" : "Atendimento"}&local_atendimento=${local_atendimento}&onlycontent=true`}
          width="99%"
          height="99%"
          onLoad={handleLoad}
          style={{ display: loading ? 'none' : 'block' }}
          title="Atendimento 156"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggle} color="primary">
          Fechar
        </Button>

      </DialogActions>
    </Dialog>
  </>);
}