import React, { useEffect } from 'react';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import QrCode from 'qrcode.react';
import { useStyles } from "./style";

import { cpfMask } from '../../utils/cpfFormat';
import dateFormat, { extractHourFormat } from '../../utils/dateFormat';
import { MdClose, MdOutlinePrint } from 'react-icons/md';
import { getPassDataPrint } from '../../services/agendamento';

type IdentificationProps = 'cpf' | 'rg' | 'titulo' | 'nenhum';

export default function PrintScheduler() {
  const classes = useStyles();
  const [stateData, setStateData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [scheduleData, setScheduleData] = React.useState<any>({});

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const encodedState = urlParams.get('state');
    if (encodedState) {
      const decodedState = JSON.parse(atob(encodedState));
      setStateData(decodedState);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const ticketOffice = sessionStorage.getItem("gov_ticket_office");
        if (ticketOffice) {
          const dataTicket = JSON.parse(ticketOffice);
          const token: string | null = sessionStorage.getItem(
            "gov_access_token_sso"
          );
          if (dataTicket?.id) {
            const { data } = await getPassDataPrint(
              token || '',
              dataTicket?.id,
              stateData?.id
            );
            if (data) {
              setScheduleData(data);
            }
          }
        }
      } catch (error) {
        alert('Erro ao buscar dados do agendamento, Não foi possível imprimir a Senha.');
      } finally {
        setLoading(false);
      }
    };
    if (stateData?.id) {
      getData();
    }
  }, [stateData]);

  useEffect(() => {
    if (scheduleData?.protocolo && !loading) {
      window.print();
    }
  }, [scheduleData, loading]);

  function handleDateFormat(date: string) {
    return Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    }).format(new Date(date));
  }

  function handleIdentificationInfo(type: IdentificationProps): string {
    switch (type) {
      case 'cpf':
        return `CPF: ${cpfMask(scheduleData?.cpf || '')}`;
      case 'rg':
        return `RG: ${scheduleData?.documento}`;
      case 'titulo':
        return `Título de eleitor: ${scheduleData?.documento}`;
      default:
        return `CPF: ${cpfMask(scheduleData?.cpf || 'Não informado')}`;
    }
  }

  const getHrefComprovante = (ticket: string) => {
    const baseUrl = process.env.REACT_APP_PORTAL_URL || '';
    if (!!ticket) {
      return `${baseUrl}/comprovante/agendamento/${ticket}`
    }
    return '#';
  }

  const getTypePriority = (type: string) => {
    if (type.startsWith("PA")){
      return "Alta Prioridade";
    }
    if (type.startsWith("P")){
      return "Prioridade";
    }
    if (type.startsWith("N")){
      return "Normal";
    }
    return "Normal"
  }

  

  return (

    <Box className={classes.container}>
      {loading ? <Box display="flex" justifyContent="center">
        <CircularProgress size={80}/>
      </Box> : <>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.print()}
          className={classes.buttons}
        >
          <MdOutlinePrint size={20} style={{ marginRight: 5 }} />
          Imprimir
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.close()}
          className={classes.buttons}
        >
          <MdClose size={20} style={{ marginRight: 5 }} />
          Sair
        </Button>

        <Box className={classes.containerQrCode}>
            <Typography style={{
              marginBottom: 10,
              fontSize: 12,
            }}>
              Atendimento {getTypePriority(scheduleData?.senha || '')}
            </Typography>
          <Box>
            <QrCode
              size={100}
              value={getHrefComprovante(scheduleData?.ticket || '')}
            />
          </Box>
        </Box>

        <Box className={classes.infoFieldset}>
          <Typography className={classes.title}>
            Senha
          </Typography>
          <Typography style={{
            fontSize: 24,
          }}>
            {scheduleData?.senha || ''}
          </Typography>
            <Typography className={classes.title}>
              Tipo de atendimento
            </Typography>
            <Typography style={{
              fontSize: 14,
            }}>
              Atendimento {scheduleData?.tipo === "Presencial" ? "Agendado" : scheduleData?.tipo}
            </Typography>
            <Typography className={classes.title}>Nome</Typography>
            <Typography className={classes.subTitle}>
              {scheduleData?.nome || ''}
            </Typography>
          <Typography className={classes.title}>Identificação</Typography>
          <Typography className={classes.subTitle}>
            {scheduleData?.tipo_documento && handleIdentificationInfo(scheduleData?.tipo_documento as IdentificationProps)}
          </Typography>
          <Typography className={classes.title}>
            Serviço
          </Typography>
          <Typography className={classes.subTitle}>
            {scheduleData?.servico_titulo || ''}
          </Typography>
          <Typography className={classes.title}>
            Local de atendimento
          </Typography>
          <Typography className={classes.subTitle}>
            {scheduleData?.local_atendimento || ''}
          </Typography>
            {scheduleData?.tipo === "Presencial" && <>
              <Typography className={classes.title}>
                Data/Hora (aproximado)
              </Typography>
              <Typography className={classes.subTitle}>
                {scheduleData?.data && `${dateFormat(scheduleData?.data || '')} - ${scheduleData?.hora || ''}`}
              </Typography></>}

          <Typography className={classes.title}>
            Protocolo
          </Typography>
          <Typography className={classes.subTitle}>
            {scheduleData?.protocolo || ''}
          </Typography>
        </Box>
        <Box className={classes.dateInfoFieldset}>
          <Typography className={classes.dateInfoText}>
            {scheduleData?.updated_at && `Emitida às ${extractHourFormat(scheduleData?.updated_at || '')} do dia ${handleDateFormat(scheduleData?.updated_at || '')}`}
          </Typography>
        </Box>

      </>}

    </Box>
  )
}