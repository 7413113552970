import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider,
  Link,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  useMediaQuery,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { CheckCircleOutline, Close, Menu, PersonRounded } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AgendamentoDiario,
  Schedule,
  ScheduleUser,
} from "../../../../interfaces/agendamento";
import { ServicoInterno, UnidadeSetor } from "../../../../interfaces/servico";
import {
  agendarInterno,
  checarHorariosInternos,
} from "../../../../services/agendamento";
import { getInterService } from "../../../../services/servicos";
import { dateFormat } from "../../../../utils/dateFormat";
import useStyles from "./style";
import ptBR from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { format, isValid } from "date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { cpfMask } from "../../../../utils/cpfFormat";
import { AgendamentoInternoReturn } from "../../../../interfaces/agendamentoInterno";
import { addObservationAgendamentoDiario, updateStatusAgendamentoDiario } from "../../../../services/agendamentoOrganico";
import React from "react";
import toastMessage from "../../../../utils/handleToastMessage";
import { MdHelp } from "react-icons/md";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  openAttendanceStop: boolean;
  setOpenAttendanceStop: (openAttendanceStop: boolean) => void;
  schedule: undefined | AgendamentoDiario;
}

export default function AttendanceDialogStop({
  setOpenAttendanceStop,
  openAttendanceStop,
  schedule,
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const [valueTab, setValueTab] = useState(0);
  const [observation, setObservation] = useState(undefined);
  const [interServices, setInterServices] = useState<
    undefined | ServicoInterno[]
  >();

  const [sectorUnits, setSectorUnits] = useState<undefined | UnidadeSetor[]>();
  const [scheduleSelected, setScheduleSelected] = useState<string>("");

  const [serviceSelected, setServiceSelected] = useState<
    undefined | ServicoInterno
  >();

  const [selectedDate, handleDateChange] = useState<Date>(new Date());
  const [unitySelected, setUnitySelected] = useState<string>("");
  const [scheduleInterReturn, setScheduleInterReturn] = useState<
    undefined | AgendamentoInternoReturn
  >();

  const [scheduleService, setScheduleService] = useState<
    string | Schedule | undefined
  >();
  const [scheduleServiceUser, setScheduleServiceUser] = useState<
    ScheduleUser | string | undefined
  >();

  const [isOk, setIsOk] = useState<boolean>(false);

  const matchesMobile = useMediaQuery("(min-width:576px)");
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type?: "menu"
  ) => {
    if (type === "menu") {
      setAnchorElMenu(event.currentTarget);
    }
  };


  const handleChangeTabs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
  };
  const disableWeekends = (date: MaterialUiPickersDate): boolean =>
    date
      ? date.getDay() === 0 ||
      date.getDay() === 6 ||
      format(date, "yyyy-MM-dd") === "2021-02-16"
      : false;
  const handleClose = () => {
    if (isOk) {
      return history.go(0);
    }
    return setOpenAttendanceStop(false);
  };

  const handleFinishAttendance = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;

      setLoading(true);
      if (token) {
        const { status } = await updateStatusAgendamentoDiario(
          token,
          scheduleId,
          "Concluído",
          guiche,
        );
        if (status === 200 && !!observation) {
          await addObservationAgendamentoDiario(token, scheduleId, observation || "");
        }
        toastMessage({
          type: "success",
          message: "Atendimento Concluído com sucesso!",
        });
        setIsOk(true);
      }
    } catch (error) {
      toastMessage({
        type: "error",
        message: "Erro ao finalizar atendimento!",
      });
    } finally {
      setLoading(false);
    }
  };

  const interServiceToService = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await getInterService(
        process.env.REACT_APP_API_SISECI_KEY ?? "",
        id
      );
      if (data) {
        setInterServices(data);
      }
      // console.log("inter services", data);
    } catch (error) {
      console.log("Erro", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleChangeService = (event: any) => {
    setSectorUnits(undefined);

    let serviceFilter = interServices?.filter(
      ({ id }) => id === parseInt(event?.target?.value)
    );
    if (serviceFilter?.[0]) {
      setSectorUnits(serviceFilter[0].unidade_setor);
      setServiceSelected(serviceFilter[0]);
    }
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date) && serviceSelected) {
      try {
        const { data } = await checarHorariosInternos(
          date,
          unity,
          serviceSelected.id
        );
        setScheduleService(data);
      } catch (err: any) {
        if (err?.response) {
          setScheduleService(err?.response?.data?.message || "");
        } else {
          setScheduleService("Algo inesperado ocorreu");
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const handleSchedulerService = async () => {  
    setLoading(true);
    const token: string | null = sessionStorage.getItem(
      "gov_access_token_sso"
    );
    if (token && schedule && serviceSelected) {      
      try {
        const { data } = await agendarInterno(
          token,
          schedule.cpf,
          schedule?.user?.id || 0,
          selectedDate,
          unitySelected,
          serviceSelected.id,
          scheduleSelected
        );
        if (data) {
          toastMessage({
            type: "success",
            message: "Agendamento realizado com sucesso",
          });
          setScheduleInterReturn(data);
          await handleFinishAttendance(schedule.id);
        }
      } catch (err: any) {
        toastMessage({
          type: "error",
          message: err?.response?.data?.message || "Algo inesperado ocorreu",
        });
        if (err?.response?.data?.message) {

          setScheduleServiceUser(err.response.data.message);
        } else {
          setScheduleServiceUser("Algo inesperado ocorreu.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (schedule?.servico && schedule.servico.id) {
      interServiceToService(schedule.servico.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  return (
    <Dialog
      fullWidth
      open={openAttendanceStop}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isOk ? (
        <DialogTitle className={classes.header}>
          Atendimento Concluído com sucesso!
        </DialogTitle>
      ) : (
        <>
          <DialogTitle className={classes.header}>
            {matchesMobile ? (
              <Tabs
                value={valueTab}
                onChange={handleChangeTabs}
                aria-label="simple tabs example"
              >
                <Tab label="Finalizar atendimento" {...a11yProps(0)} />
                {interServices && interServices.length && (
                  <Tab label="Novo agendamento" {...a11yProps(1)} />
                )}
              </Tabs>
            ) : (
              <>
                {interServices && interServices.length ? (
                  <IconButton onClick={(e): void => handleClick(e, "menu")}>
                    <Menu />
                  </IconButton>
                ) : (
                  ""
                )}
                {valueTab === 0 && `Finalizar atendimento`}
                {valueTab === 1 && `Novo agendamento`}

                <Popover
                  open={Boolean(anchorElMenu)}
                  anchorEl={anchorElMenu}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box>
                    <Divider />

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setValueTab(0);
                        handleCloseMenu();
                      }}
                    >
                      <Typography className={classes.textMenuItem}>
                        Finalizar atendimento
                      </Typography>
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setValueTab(1);
                        handleCloseMenu();
                      }}
                    >
                      <Typography className={classes.textMenuItem}>
                        Novo agendamento
                      </Typography>
                    </MenuItem>
                  </Box>
                </Popover>
              </>
            )}
          </DialogTitle>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
        </>
      )}

      {isOk ? (
        <>
          <DialogContent>
            <CheckCircleOutline
              style={{
                fontSize: 60,
                color: "#94C120",
                textAlign: "center",
                width: "100%",
              }}
            />
            {schedule && (
              <>
                <Box style={{ textAlign: "center" }}>
                  <Box className={classes.titleDependents}>
                    <Typography className={classes.textModalTitleUDependent}>
                      {schedule.nome}
                    </Typography>
                    <Typography className={classes.textModalTitleUDependentCPF}>
                      {cpfMask(schedule.cpf)}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={3} style={{ textAlign: "center" }}>
                  <Typography className={classes.textModalTitleServico}>
                    serviço
                  </Typography>
                  <Typography className={classes.textModalServico}>
                    {schedule.servico.titulo}
                  </Typography>
                </Box>

                {schedule?.protocolo_atendimento && (
                  <Box mb={3} style={{ textAlign: "center" }}>
                    <Typography className={classes.textModalTitleServico}>
                      Protocolo atendimento
                    </Typography>
                    <Typography className={classes.textModalServico}>
                      {schedule.protocolo_atendimento}
                    </Typography>
                  </Box>
                )}
                {scheduleInterReturn && (
                  <>
                    <Divider light />
                    <Typography
                      variant="body1"
                      style={{ textAlign: "center", margin: 10 }}
                    >
                      Novo Agendamento realizado:
                    </Typography>
                    <Box>
                      <Typography className={classes.textModalTitleServico}>
                        serviço
                      </Typography>
                      <Typography className={classes.textModalInterService}>
                        {scheduleInterReturn.servico_titulo}
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Typography className={classes.textModalTitleServico}>
                        data/hora
                      </Typography>
                      <Typography className={classes.textModalInterService}>
                        {`${dateFormat(scheduleInterReturn.data)} às ${scheduleInterReturn.hora
                          }`}
                      </Typography>
                    </Box>
                  </>
                )}
              </>
            )}
            <Divider light />
            <Button
              style={{
                color: "#FFF",
                fontSize: 14,
                float: "right",
                marginTop: 20,
              }}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClose}
            >
              FECHAR
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          {schedule && (
            <>
              <TabPanel value={valueTab} index={0}>
                <DialogContent>
                  <PersonRounded fontSize="large" color="primary" />
                  <Box className={classes.titleDependents}>
                    <Typography className={classes.textModalTitleUDependent}>
                      {schedule.nome}
                    </Typography>
                    <Typography className={classes.textModalTitleUDependentCPF}>
                      {cpfMask(schedule.cpf)}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.textModalTitle}>
                      serviço
                    </Typography>
                    <Typography className={classes.textModal}>
                      {schedule.servico.titulo}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.textModalTitle}>
                      data/hora
                    </Typography>
                    <Typography className={classes.textModal}>
                      {`${dateFormat(schedule.data)} às ${schedule.hora}`}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.textModalTitle}>
                      status
                    </Typography>
                    <Typography className={classes.textModal}>
                      {schedule.status}
                    </Typography>
                  </Box>
                  <Box mb={3}>
                    <Typography className={classes.textModalTitle}>
                      observações: (opcional)
                      <Tooltip title="As informações deste campo serão compartilhadas no histórico do cidadão.">
                        <span>
                          <IconButton disabled>
                            <MdHelp />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Typography>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={5}
                      variant="outlined"
                      margin="normal"
                      disabled={loading}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          fontFamily: "Mulish",
                          fontWeight: 400,
                        },
                        placeholder: "As informações deste campo serão compartilhadas no histórico do cidadão.",
                      }}
                      style={{ width: "100%", marginBottom: 10 }}
                      value={observation}
                      onChange={(e: any) => {
                        setObservation(e?.target?.value);
                      }}
                    />
                  </Box>
                  <Divider light />
                  <div className={classes.buttonCloseModal}>
                    {!loading && (
                      <Link className={classes.link} onClick={handleClose}>
                        fechar
                      </Link>
                    )}
                    <Button
                      style={{ color: "#FFF", fontSize: 14 }}
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => handleFinishAttendance(schedule.id)}
                      disabled={loading}
                    >
                      {!loading ? "CONFIRMAR" : <CircularProgress />}
                    </Button>
                  </div>
                </DialogContent>
              </TabPanel>
              {interServices && interServices.length > 0 && (
                <TabPanel value={valueTab} index={1}>
                  <div id="input-servico">
                    <FormControl
                      classes={{ root: classes.root }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selecione o serviço
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={handleChangeService}
                        label="Selecione o serviço"
                        classes={{
                          select: classes.menuItem,
                        }}
                      >
                        {interServices &&
                          interServices.map((service) => (
                            <MenuItem
                              key={service.slug}
                              className={classes.menuItem}
                              value={service.id}
                            >
                              {service.titulo}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div id="input-unidade">
                    <FormControl
                      classes={{ root: classes.root }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Selecione a unidade
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(event) => {
                          setUnitySelected(event.target.value as string);
                          getSchedulers(
                            selectedDate,
                            event.target.value as string
                          );
                        }}
                        label="Selecione a unidade"
                        classes={{
                          select: classes.menuItem,
                        }}
                        disabled={!sectorUnits}
                      >
                        {sectorUnits &&
                          sectorUnits.map(({ id, setor }) => (
                            <MenuItem
                              key={setor.unidade.slug_unidade}
                              className={classes.menuItem}
                              value={setor.unidade.slug_unidade}
                            >
                              {setor.unidade.nome}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div id="input-data">
                    <FormControl
                      classes={{ root: classes.root }}
                      error={
                        typeof scheduleService === "string" ||
                        typeof scheduleServiceUser === "string"
                      }
                      disabled={!unitySelected && !sectorUnits}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <MuiPickersUtilsProvider
                        locale={ptBR}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          disableFuture={false}
                          error={
                            typeof scheduleService === "string" ||
                            typeof scheduleServiceUser === "string"
                          }
                          shouldDisableDate={disableWeekends}
                          minDateMessage="Você não pode agendar em dias anteriores"
                          invalidDateMessage="A data informada é inválida"
                          minDate={new Date()}
                          InputProps={{
                            className: classes.menuItem,
                            classes: { adornedEnd: classes.adornedEnd },
                          }}
                          autoOk
                          disabled={loading}
                          variant="inline"
                          inputVariant="outlined"
                          label="Selecione o dia"
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          InputAdornmentProps={{
                            position: "end",
                            color: "#000",
                          }}
                          onChange={(date) => {
                            if (date && isValid(date)) {
                              handleDateChange(date);
                              if (unitySelected) {
                                getSchedulers(date, unitySelected);
                              }
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {typeof scheduleService === "string" && (
                        <FormHelperText>{scheduleService}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div id="input-horario">
                    <FormControl
                      classes={{ root: classes.root }}
                      disabled={
                        !unitySelected ||
                        !selectedDate ||
                        typeof scheduleService === "string"
                      }
                      variant="outlined"
                      className={classes.formControl}
                      error={typeof scheduleServiceUser === "string"}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Escolha o horário
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        classes={{
                          select: classes.menuItem,
                        }}
                        onChange={(event): void => {
                          setScheduleSelected(event.target.value as string);
                        }}
                        label="Escolha o horário"
                      >
                        {typeof scheduleService !== "string" &&
                          scheduleService?.horarios.map((schedule) => (
                            <MenuItem
                              key={schedule}
                              className={classes.menuItem}
                              value={schedule}
                            >
                              {schedule}
                            </MenuItem>
                          ))}
                      </Select>
                      {typeof scheduleServiceUser === "string" && (
                        <FormHelperText>{scheduleServiceUser}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <Box mb={3}>
                    <Typography className={classes.textModalTitle}>
                      deseja inserir alguma observação sobre o atendimento atual?
                    </Typography>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={5}
                      variant="outlined"
                      margin="normal"
                      disabled={loading}
                      inputProps={{
                        style: {
                          fontSize: 14,
                          fontFamily: "Mulish",
                          fontWeight: 400,
                        },
                      }}
                      style={{ width: "100%", marginBottom: 10 }}
                      value={observation}
                      onChange={(e: any) => {
                        setObservation(e?.target?.value);
                      }}
                    />
                  </Box>
                  <Divider light />
                  <div className={classes.buttonCloseModal}>
                    {!loading && (
                      <Link className={classes.link} onClick={handleClose}>
                        fechar
                      </Link>
                    )}
                    <Button
                      style={{ color: "#FFF", fontSize: 12, flex: 0.5 }}
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSchedulerService}
                      disabled={
                        loading ||
                        !unitySelected ||
                        !selectedDate ||
                        typeof scheduleService === "string" ||
                        typeof scheduleService === "undefined" ||
                        !scheduleSelected
                      }
                    >
                      {!loading ? "FINALIZAR E AGENDAR" : <CircularProgress />}
                    </Button>
                  </div>
                </TabPanel>
              )}
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
