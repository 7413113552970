import { ScheduleProvider } from "../../providers/ScheduleProvider";
import reducerSchedule, { initialScheduleState } from "../../store/schedulesReducer";
import { SchedulesContent } from "./components";


export default function Schedules(): JSX.Element {

  return (
    <ScheduleProvider initialState={initialScheduleState} reducer={reducerSchedule}>
      <SchedulesContent/>
    </ScheduleProvider>
  );
}
