export const dateFormat = (date: string, withHour = false): string => {
  if (withHour) {
    const dateHourPart = date.split('T');
    const datePart = dateHourPart[0].split('-');
    const hourPart = dateHourPart[1].split('.');
    return `${datePart[2]}/${datePart[1]}/${datePart[0]} ${hourPart[0]}`;
  }
  const datePart = date.split('-');
  return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
};

export const extractDateFormat = (date: string): string => {
  const dateHourPart = date.split('T');
  const datePart = dateHourPart[0].split('-');
  return `${datePart[2]}/${datePart[1]}/${datePart[0]}`;
};
export const extractHourFormat = (date: string): string => {
  const dateHourPart = date.split('T');
  const hourPart = dateHourPart[1].split('.');
  return `${hourPart[0]}`;
};

export const getUTCDate = (date: string) => {
  if (!date) return '';
  const year = date.split('-')[0];
  const month = date.split('-')[1];
  const day = date.split('-')[2];
  return new Date(Date.UTC(+year, +month - 1, +day, 3));
};

export const extractDateFormatAlternative = (date: string): string => {
  const datePart = date.split('/');
  return `${new Date(datePart[2] + '/' + datePart[1] + '/' + datePart[0])}`;
};

export const getLocalDateString = (date: Date): string => {
  // Obter a diferença de fuso horário em minutos e converter para milissegundos
  const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;

  // Subtrair a diferença de fuso horário da data
  const localDate = new Date(date.getTime() - timezoneOffset);

  // Obter componentes da data local
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
  const day = String(localDate.getDate()).padStart(2, '0');

  // Retornar no formato 'YYYY-MM-DD'
  return `${year}-${month}-${day}`;
};

export default dateFormat;
