import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { StyledTableCell } from "../../style";
import { AlarmOn, People, Print } from "@material-ui/icons";
import PaginationService from "../../../../Components/PaginationService";
import { useSchedules } from "../../useSchedules";
import { cpfMask } from "../../../../utils/cpfFormat";
import dateFormat from "../../../../utils/dateFormat";
import { useEffect, useState } from "react";
import { TabPanel } from "../SchedulesContent";
import { Agendamento } from "../../../../interfaces/agendamento";
import { TableRowCheckTime } from '../../../../Components';
import ModalUnfreeze from "../ModalUnfreeze";


type TabAttendanceTodayProps = {
  value: number;
  index: number;
};

export default function TabAttendanceToday({ value, index }: TabAttendanceTodayProps) {
  const {
    theme,
    classes,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    agendamentosDaVezHoje,
    agendamentosHoje,
    loadingToday,
    dataSchedulesToday,
    getHrefComprovante,
  } = useSchedules();


  const [openFreeze, setOpenFreeze] = useState(false);
  const [scheduleSelected, setScheduleSelected] = useState<undefined | Agendamento>();
  const handleOpenFreeze = (schedule: Agendamento) => {
    setOpenFreeze(true);
    setScheduleSelected(schedule);
  }
  const searchParams = new URLSearchParams(window.location.search);

  const getAgendamentosDaVezHoje = async () => {
    const tipo_prioridade = searchParams.get("tipo_prioridade")!
      const cpf = searchParams.get("cpf")!
      const nome = searchParams.get("nome")!
      const senha = searchParams.get("senha")!
      const status = searchParams.get("status")!
      const tipo = searchParams.get("tipo")!
      const page = searchParams.get("page")!

      agendamentosDaVezHoje({ 
        page: Number(page) || 1, 
        tipo_prioridade,
        cpf,
        nome,
        senha,
        status,
        tipo,
      });
  }

  useEffect(() => {
    agendamentosHoje()
    getAgendamentosDaVezHoje()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Box style={{ overflow: "auto" }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>NOME</StyledTableCell>
              <StyledTableCell align="left">CPF</StyledTableCell>
              <StyledTableCell align="left">DATA/HORA</StyledTableCell>
              <StyledTableCell align="left">TIPO</StyledTableCell>
              <StyledTableCell align="left">STATUS</StyledTableCell>
              <StyledTableCell align="left" colSpan={2}>SERVIÇO</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingToday && <TableRow>
              <TableCell colSpan={7} style={{ textAlign: 'center' }}><CircularProgress /></TableCell>
            </TableRow>}
            {!!dataSchedulesToday?.results &&
              dataSchedulesToday?.results?.map((rowsSchedulesToday: Agendamento) => (
                <TableRowCheckTime key={rowsSchedulesToday.ticket} data={rowsSchedulesToday.data} hora={rowsSchedulesToday.hora} status={rowsSchedulesToday.status} >
                  <StyledTableCell component="th" scope="row">
                    {rowsSchedulesToday?.user?.first_name || rowsSchedulesToday?.nome}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {cpfMask(rowsSchedulesToday.cpf)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${dateFormat(rowsSchedulesToday.data)} ${rowsSchedulesToday.hora
                      }`}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.status}
                    align="left"
                  >
                    {rowsSchedulesToday.tipo}
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.status}
                    align="left"
                  >
                    {rowsSchedulesToday.status}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {rowsSchedulesToday.servico.titulo}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {rowsSchedulesToday.para_dependente && rowsSchedulesToday.dependentes && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.buttonIcon}
                        onClick={() => {
                          setSchedulesDependentCurrent(rowsSchedulesToday);
                          setOpenDependentsDialog(true);
                        }}
                        title="Dependentes"
                      >
                        <People />
                      </Button>

                    )}
                    <Tooltip title={`Comprovante Agendamento`}>
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          component="a"
                          href={getHrefComprovante(rowsSchedulesToday.ticket)}
                          target="_blank"
                          className={classes.buttonIcon}
                        >
                          <Print />
                        </Button>
                      </span>
                    </Tooltip>
                    {/* {calculateCountDown(`${rowsSchedulesToday.data}T${rowsSchedulesToday.hora}`) > 0 && rowsSchedulesToday.status === "Aguardando" && (<Tooltip title={`Não compareceu`}>
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          className={classes.buttonIconAttention}
                          onClick={() => {
                            // setRowsSchedulesToday(rowsSchedulesToday);
                            // setOpenAttendanceCancel(true)
                          }}
                        // disabled={callingPass}
                        >
                          <Icon className="material-icons-outlined">
                            person_off
                          </Icon>
                        </Button>
                      </span>
                    </Tooltip>)} */}


                    {((rowsSchedulesToday.status === "Congelado") && rowsSchedulesToday.tipo !== "Online" && rowsSchedulesToday?.congelado) && (
                      <Tooltip title={`Descongelar Atendimento`}>
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#90caf9",
                              color: "rgb(255, 255, 255)"
                            }}
                            onClick={() => handleOpenFreeze(rowsSchedulesToday)}
                            className={classes.buttonIcon}
                          // disabled={callingPass || callingUnlockPass}
                          >
                            <AlarmOn />
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </StyledTableCell>
                </TableRowCheckTime>
              ))}
            {!!!dataSchedulesToday?.results?.length && !loadingToday && (
              <TableRow>
                <StyledTableCell colSpan={7} align="center">
                  <h2>Nenhum agendamento para hoje</h2>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <PaginationService
        getSearch={(page) => agendamentosHoje({ page })}
        listSearch={{
          count: dataSchedulesToday?.count,
          next: dataSchedulesToday?.next,
          previous: dataSchedulesToday?.previous,
          current: dataSchedulesToday?.current,
          total_pages: dataSchedulesToday?.total_pages,
        }}
      />
      {scheduleSelected && <ModalUnfreeze
        open={openFreeze}
        setOpen={setOpenFreeze}
        agendamento={scheduleSelected} />}
    </TabPanel>
  );
};