import React, { useEffect, useState } from "react";
import {
  Box,
} from "@material-ui/core";
import * as jose from 'jose'
import { useStateValue } from "../../providers/StateProvider";

export default function MetabaseIframe(): JSX.Element {

    const [{ currentTicketOffice }] = useStateValue();


    const [iframe, setIframe] = useState('')
    async function generateIframeURL() {
        const unidade_id = currentTicketOffice?.setor?.unidade?.slug_unidade || currentTicketOffice?.unidade?.slug_unidade;
        console.log({unidade_id});
        
        let REACT_APP_METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL || "";
        let REACT_APP_METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY || "";
        
        let payload: jose.JWTPayload = {
            resource: { dashboard: 11 },
            params: {},
            exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
        };
        let token = await new jose.SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).sign(new TextEncoder().encode(REACT_APP_METABASE_SECRET_KEY));
        let iframeUrl = REACT_APP_METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";
        setIframe(iframeUrl)
    }
    
    useEffect(() => {
        generateIframeURL() 
    }, [])
    return (
        <Box>
            <iframe
                title="Dados"
                src={iframe}
                frameBorder={0}
                width={'100%'}
                height={850}
                allowTransparency
            ></iframe>
        </Box>
    );
}
