import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "./style";
import {
  BarChart,
  Event as EventIcon,
  Home as HomeIcon,
} from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useStateValue } from "../../providers/StateProvider";

export default function SideBar(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [{ currentTicketOffice }] = useStateValue();
  const { pathname } = location;
  const matchesSm = useMediaQuery("(max-width:576px)");

  const goPage = (page: string) => {
    history.push(page);
  }

  return (
    <>
      {!matchesSm && (
        <Box className={classes.main}>
          <List className={classes.listMenu}>
            <ListItem button onClick={(): void => goPage("/home")}>
              <ListItemIcon
                className={classes.iconsMenu}
                style={{
                  backgroundColor:
                    pathname === "/home"
                      ? "#FFFFFF"
                      : "rgba(255, 255, 255, 0.08)",
                }}
              >
                <HomeIcon
                  style={{ color: pathname === "/home" ? "#053396" : "#fff" }}
                />
              </ListItemIcon>
            </ListItem>

            {currentTicketOffice && currentTicketOffice.tipo === "Atendimento" && (
              <ListItem button onClick={(): void => goPage("/agendamento")}>
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/agendamento"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color: pathname === "/agendamento" ? "#053396" : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {currentTicketOffice && currentTicketOffice.tipo === "Presencial" && (
              <ListItem button onClick={(): void => goPage("/agendamento")}>
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/agendamento"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color: pathname === "/agendamento" ? "#053396" : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {currentTicketOffice && currentTicketOffice.tipo === "Online" && (
              <ListItem button onClick={(): void => goPage("/atendimento")}>
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/atendimento"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color: pathname === "/atendimento" ? "#053396" : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {currentTicketOffice && currentTicketOffice.tipo === "Interno" && (
              <ListItem
                button
                onClick={(): void => goPage("/agendamento_interno")}
              >
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/agendamento_interno"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color:
                        pathname === "/agendamento_interno"
                          ? "#053396"
                          : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {currentTicketOffice && currentTicketOffice.tipo === "Consulta" && (
              <ListItem
                button
                onClick={(): void => goPage("/consulta_agendamento")}
              >
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/consulta_agendamento"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color:
                        pathname === "/consulta_agendamento"
                          ? "#053396"
                          : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            {currentTicketOffice && currentTicketOffice.tipo === "Diario" && (
              <ListItem
                button
                onClick={(): void => goPage("/atendimento_diario")}
              >
                <ListItemIcon
                  className={classes.iconsMenu}
                  style={{
                    backgroundColor:
                      pathname === "/atendimento_diario"
                        ? "#FFFFFF"
                        : "rgba(255, 255, 255, 0.08)",
                  }}
                >
                  <EventIcon
                    style={{
                      color:
                        pathname === "/atendimento_diario" ? "#053396" : "#fff",
                    }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
            <ListItem button onClick={(): void => goPage("/metabase")}>
              <ListItemIcon
                className={classes.iconsMenu}
                style={{
                  backgroundColor:
                    pathname === "/metabase"
                      ? "#FFFFFF"
                      : "rgba(255, 255, 255, 0.08)",
                }}
              >
                <BarChart
                  style={{ color: pathname === "/metabase" ? "#053396" : "#fff" }}
                />
              </ListItemIcon>
            </ListItem>
          </List>
        </Box>
      )}
    </>
  );
}
