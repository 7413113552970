import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { SchedulesResponse } from "../interfaces/agendamento";
import { AgendamentoInternoReturn } from "../interfaces/agendamentoInterno";
import { apisiseci } from "./api";

export const getAgendamentos = (
  token: string,
  params?: {
    periodo?: "hoje" | "anteriores";
    anteriores?: boolean;
    status?:
      | "Cancelado"
      | "Concluído"
      | "Em Andamento"
      | "Aguardando"
      | "Congelado"
      | "Compareceu"
      | "Não Compareceu"
      | string;
    page?: number;
    unidade?: string;
    guiche_id?: string;
    in_status?: string;
    out_status?: string;
    davez?: string;
    items_size?: number;
    cpf?: string;
    tipo_prioridade?: string;
    tipo?: string;
    // em_andamento?: boolean,
    senha?: string;
    nome?: string;
  }
) =>
  apisiseci.get<SchedulesResponse>("agendamento/agendamentos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...params,
    },
  });

export const getCheckInterval = (
  token: string,
  servico: number,
  unidade: string,
  data_inicio: string,
  data_fim: string,
  tipo: "Online" | "Presencial" | "Espontâneo"
): Promise<AxiosResponse> =>
  apisiseci.get<String[]>(
    `agendamento/checar_intervalo/?servico=${servico}&unidade=${unidade}&data_inicio=${data_inicio}&data_fim=${data_fim}&tipo=${tipo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateStatusAgendamento = (
  token: string,
  id: number | string,
  status:
    | "Cancelado"
    | "Concluído"
    | "Em Andamento"
    | "Aguardando"
    | "Não Compareceu"
    | "Encaminhado"
    | "Compareceu",
  guiche: string,
  prioridade?: boolean,
  prioridade_alta?: boolean,
  descricao_atendimento?: string,
  servico_interno?: string | number
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/agendamentos/${id}/`,
    {
      id,
      status,
      guiche,
      prioridade,
      prioridade_alta,
      descricao_atendimento,
      servico_interno,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        guiche_id: guiche,
      },
    }
  );

export const addObservation = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/observacao/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const callPassword = (
  token: string,
  guiche_id: string,
  agendamento_id: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/atendimento/chamar_senha/?guiche_id=${guiche_id}`,
    {
      agendamento_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const unlockPassword = (
  token: string,
  guiche_id: string,
  agendamento_id: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/atendimento/liberar/?guiche_id=${guiche_id}`,
    {
      agendamento_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const pauseAttendance = (
  token: string,
  guiche_id: string,
  agendamento_id: string,
  motivo: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `/agendamento/atendimento/congelar/?guiche_id=${guiche_id}`,
    {
      agendamento_id,
      motivo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const playAttendance = (
  token: string,
  guiche_id: string,
  agendamento_id: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `/agendamento/atendimento/descongelar/?guiche_id=${guiche_id}`,
    {
      agendamento_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const checarHorariosInternos = (
  date: Date,
  unity: string,
  serviceID: number
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, "yyyy-MM-dd");
  return apisiseci.get(
    `agendamento/checar_horarios_interno/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`
  );
};

export const agendarInterno = (
  token: string,
  cpf: string,
  userId: number,
  date: Date,
  unity: string,
  serviceID: number,
  hour: string
) => {
  return apisiseci.post<AgendamentoInternoReturn>(
    `agendamento/agendar_interno/`,
    {
      cpf,
      user: userId,
      unidade: unity,
      servico: serviceID,
      data: format(date, "yyyy-MM-dd"),
      hora: hour,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const searchAgendamentos = (
  token: string,
  params?: {
    data?: string;
    cpf?: string;
    status?:
      | "Cancelado"
      | "Concluído"
      | "Em Andamento"
      | "Aguardando"
      | "Não Compareceu";
    page?: number;
    guiche_id?: string;
    tipo: string;
  }
) =>
  apisiseci.get<SchedulesResponse>(`agendamento/consulta/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const reagendar = ({
  token,
  data,
  id,
  hora,
  unidade,
  guiche_id,
  tipo,
}: {
  token: string;
  data: Date;
  id: string | number;
  hora: string;
  unidade: string;
  guiche_id: string;
  tipo: string;
}) =>
  apisiseci.post(
    "agendamento/fazer/reagendamento/",
    {
      id,
      data: format(data, "yyyy-MM-dd"),
      hora,
      unidade,
      tipo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        guiche_id,
      },
    }
  );

export const pausarGuiche = (
  token: string,
  guiche_id: string,
  motivo: string,
  observacao: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `/agendamento/atendimento/pausar/`,
    {
      guiche_id: guiche_id,
      motivo: motivo,
      observacao: observacao,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getLastPauseDate = (
  token: string,
  guiche_id: string
): Promise<AxiosResponse> =>
  apisiseci.get(
    `/agendamento/atendimento/ultima_pausa/?guiche_id=${guiche_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const retomarGuiche = (
  token: string,
  guiche_id: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `/agendamento/atendimento/retornar/`,
    {
      guiche_id: guiche_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

//[GET] api/agendamento/atendimento/obter senha/?guiche_id=&agendamento_id=

export const getPassDataPrint = (
  token: string,
  guiche_id: string,
  agendamento_id: string
): Promise<AxiosResponse> =>
  apisiseci.get(`/agendamento/atendimento/obter_senha/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      guiche_id,
      agendamento_id,
    },
  });
