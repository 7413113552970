import {  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {useState } from "react";
import useStyles from "./style";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  protocol: string;
  cpf_atendente: string;
};

export default function Modal156ListDemand({
  open,
  setOpen,
  protocol,
  cpf_atendente,
}: Props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const handleToggle = async () => {
    setOpen(!open);
    setLoading(true);

  };

  const token = sessionStorage.getItem('gov_access_token_sso');
  const baseUrl = `${process.env.REACT_APP_156_URL}/demanda-agendamento`;

  const handleLoad = () => {
    setLoading(false);
  };

  return (<>
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen
    >
      <IconButton onClick={handleToggle} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Demandas do Agendamento
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading  &&
          <Box style={{
            display: 'flex',
            width: '100%',
            height: '600',
            justifyContent: 'center',
            alignItems: 'center',

          }}>
            <CircularProgress /></Box>
        }
        <iframe
          src={`${baseUrl}?protocolo=${protocol}&token=${token}&cpf_atendente=${cpf_atendente}&onlycontent=true`}
          width="99%"
          height="99%"
          onLoad={handleLoad}
          style={{ display: loading ? 'none' : 'block' }}
          title="Atendimento 156"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleToggle} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}