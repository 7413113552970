import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Divider,
  Link,
  TextField,
  CircularProgress,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useState } from "react";
import { MdHelp } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Agendamento } from "../../../../interfaces/agendamento";
import {
  addObservation,
  updateStatusAgendamento,
} from "../../../../services/agendamento";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";
import toastMessage from "../../../../utils/handleToastMessage";

import useStyles from "./style";

interface Props {
  openAttendanceCancel: boolean;
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void;
  schedule: undefined | Agendamento;
}

export default function DialogCancel({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [observation, setObservation] = useState(undefined);
  const history = useHistory();
  const matchesMobile = useMediaQuery("(min-width:576px)");

  const handleClose = () => {
    setOpenAttendanceCancel(false);
  };

  const handleFinishAttendance = async (scheduleId: any) => {
    try {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;

      setLoading(true);
      if (token) {
        const { status } = await updateStatusAgendamento(
          token,
          scheduleId,
          "Cancelado",
          guiche,
        );
        if (status === 200 && !!observation) {
          await addObservation(token, scheduleId, observation || "");
        }
        toastMessage({
          type: "success",
          message: "Atendimento cancelado com sucesso!",
        });
        setTimeout(() => {
          setLoading(false);
          history.go(0);
        }, 2000);   
      }
    } catch (error) {
      toastMessage({
        type: "error",
        message: "Erro ao cancelar atendimento",
      });
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton onClick={handleClose} className={classes.buttonClose}>
        <Close />
      </IconButton>
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Deseja fazer o cancelamento do agendamento?
        </Typography>
      </DialogTitle>
      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule?.user?.first_name || schedule?.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {cpfMask(schedule.cpf)}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              observações: (opcional)
              <Tooltip title="As informações deste campo serão compartilhadas no histórico do cidadão.">
                <span>
                  <IconButton disabled>
                    <MdHelp />
                  </IconButton>
                </span>
              </Tooltip>
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              disabled={loading}
              inputProps={{
                style: { fontSize: 14, fontFamily: "Mulish", fontWeight: 400 },
                placeholder: "As informações deste campo serão compartilhadas no histórico do cidadão.",
              }}
              style={{ width: "100%", marginBottom: 10 }}
              value={observation}
              onChange={(e: any) => {
                setObservation(e?.target?.value);
              }}
            />
          </Box>
          <Divider light />
          <div className={classes.buttonCloseModal}>
            {!matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 15, width: "100%" }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleFinishAttendance(schedule.id)}
                disabled={loading}
              >
                {!loading ? "CONFIRMAR" : <CircularProgress />}
              </Button>
            )}
            {!loading && (
              <Link
                className={classes.link}
                onClick={handleClose}
                style={
                  !matchesMobile
                    ? { width: "100%", textAlign: "center", padding: "15px 0" }
                    : {}
                }
              >
                fechar
              </Link>
            )}
            {matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 15 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleFinishAttendance(schedule.id)}
                disabled={loading}
              >
                {!loading ? "CONFIRMAR" : <CircularProgress />}
              </Button>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
}
