import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  textHeaderModal: {
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.3,
    width: "98%",
  },
  header: {
    background: "#FFF",
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#B7B7B7",
  },
  textModal: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  textModalTitle: {
    fontSize: 14,
    color: "rgba(55, 63, 65, 0.6)",
    textTransform: "uppercase",
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "bold",
    textAlign: "left",
  },
  titleDependents: {
    display: "inline-grid",
    verticalAlign: "middle",
    margin: "15px 20px 29px",
  },
  textModalTitleUDependent: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: 18,
    letterSpacing: 0.25,
  },
  textModalTitleUDependentCPF: {
    color: "rgba(55, 63, 65, 0.6)",
    fontFamily: "Mulish",
    fontWeight: 600,
    fontSize: 16,
  },
  textButton: {
    color: "#FFF",
    fontFamily: "Mulish",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "20px",
  },
  button: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.light,
    },
    borderRadius: 5,
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 100,
    margin: "10px 5px",
  },
  boxCloseModal: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "32px 0px 20px",
    alignItems: "center",
  },
  buttonCloseModal: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "32px 0px 20px",
    alignItems: "center",
  },
  close: {
    background: "#FFF",
    border: "none",
  },
}));

export default useStyles;
