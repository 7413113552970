import React, { useEffect, useState, memo } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Link,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  DialogActions,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {  checarHorariosWithDate } from "../../../../services/agendamentoOrganico";
import { cpfMask } from "../../../../utils/cpfFormat";
import ptBR from 'date-fns/locale/pt-BR';
import { useStyles } from "./style";
import { MdCheckCircleOutline, MdEvent } from "react-icons/md";
import { Agendamento } from "../../../../interfaces/agendamento";
import { getService } from "../../../../services/servicos";
import { format, isValid } from "date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { reagendar } from "../../../../services/agendamento";
import toastMessage from "../../../../utils/handleToastMessage";


interface Props {
  openSchedulerDialog: boolean;
  setOpenSchedulerDialog: (openSchedulerDialog: boolean) => void;
  schedule?: Agendamento;
}

export interface Schedule {
  horarios: string[];
}

interface ScheduleUser {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  prioridade: boolean;
  servico: number;
  setor: number;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: string;
}


function ReScheduler({
  setOpenSchedulerDialog,
  openSchedulerDialog,
  schedule,
}: Props): JSX.Element {
  console.log(schedule);
  
  const [loading, setLoading] = useState<boolean>(false);
  const [service, setService] = useState<any>();
  const [IsSavedScheduler, setIsSavedScheduler] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loadingService, setLoadingService] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);
  const [unitySelected, setUnitySelected] = useState<string>('');
  const [scheduleService, setScheduleService] = useState<string | Schedule | undefined>();
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [scheduleServiceUser, setScheduleServiceUser] = useState<ScheduleUser | string | undefined>();
  const [scheduleSelected, setScheduleSelected] = useState<string>('');



  const classes = useStyles();
  const history = useHistory();


  const disableWeekends = (date: MaterialUiPickersDate): boolean => (date
    ? date.getDay() === 0 || date.getDay() === 6 || format(date, 'yyyy-MM-dd') === '2021-02-16'
    : false);



  const handleClose = () => {
    setOpenSchedulerDialog(false);
  };

  const handleReScheduler = async () => {
    try {
      setLoading(true);
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche_id = JSON.parse(sessionStorage.getItem("gov_ticket_office") || "").id;
      const { status } = await reagendar({
        guiche_id,
        token: token as string,
        data: selectedDate as any,
        id: schedule?.id || '',
        hora: scheduleSelected,
        unidade: unitySelected, 
        tipo: schedule?.tipo || '',
      });
      if (status === 201) {
        toastMessage({
          type: "success",
          message: "Reagendado com sucesso!",
        });
        setIsSavedScheduler(true);
      }
    } catch (err: any) {
      toastMessage({
        type: "error",
        message: err?.response?.data?.message || "Erro ao reagendar!",
      });
      console.error(err?.response?.message);
    } finally {
      setLoading(false);
    }
  };

  const getUnit = async() => {
    try {
      setLoadingService(true)
      if (schedule?.servico?.slug) {
        const { data } = await getService(schedule?.servico?.slug);
        setService(data.results[0]);        
      }
    } catch (error: any) {
      toastMessage({
        type: "error",
        message: error?.response?.data?.message || "Erro ao carregar unidade!",
      });
      setErrorMessage(error?.response?.data?.message || "Erro ao carregar unidade!");
    } finally {
      setLoadingService(false);
    }
  };

  const getSchedulers = async (date: Date | null , unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      const token: string | null = sessionStorage.getItem(
        "gov_access_token_sso"
      );
      try {
        setLoadingDate(true);
        const { data } = await checarHorariosWithDate(date || new Date(), unity, service.id, token || '', schedule?.tipo || '');
        setScheduleService(data);
      } catch (err: any) {
        if (err?.response) {
          setScheduleService(err.response?.data?.message);
        } else {
          setScheduleService('Algo inesperado ocorreu');
        }
      } finally {
        setLoadingDate(false);
      }
    } else {
      setScheduleService(undefined);
    }
  };

  useEffect(() => {
    if (schedule) {
      getUnit();
    }
    // eslint-disable-next-line
  }, [schedule]);

  return (
    <Dialog
      fullWidth
      open={openSchedulerDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      
      <div style={{ display: IsSavedScheduler ? 'none' : 'initial' }}>
        <Box className={classes.headerModal}>
          <Typography className={classes.titleModal}>
            REAGENDAMENTO
          </Typography>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {schedule && (<Box>
            <PersonRounded fontSize="large" color="primary" />
            <Box className={classes.titleDependents}>
              <Typography className={classes.textModalTitleUDependent}>
                {schedule?.user?.first_name || schedule?.nome}
              </Typography>
              <Typography className={classes.textModalTitleUDependentCPF}>
                {cpfMask(schedule?.cpf)}
              </Typography>
              
            </Box>
            <Box mb={3}>
              <Typography className={classes.textModalTitle}>serviço</Typography>
              <Typography className={classes.textModal}>
                {schedule.servico.titulo}
              </Typography>
            </Box>
          </Box>)}
          {loadingService ? (
            <Box className={classes.boxLoadingIcon}>
              <CircularProgress />
            </Box>
          ) : (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
              error={typeof (errorMessage) === 'string'}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                  Selecione a unidade
              </InputLabel >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Selecione a unidade"
                error={typeof (errorMessage) === 'string'}
                onChange={(event): void => {
                  setUnitySelected(event.target.value as string);
                  getSchedulers(selectedDate, event.target.value as string);
                }}
                classes={{
                  select: classes.menuItem,
                }}
                  MenuProps={{
                    MenuListProps: {
                      disablePadding: true,
                    },
                  }}
              >
                  {service?.servicosUnidade?.map((unity: any) => (
                    <MenuItem
                      key={unity.id}
                      className={classes.menuItem}
                      value={unity.unidade.slug_unidade}
                    >
                      {unity.unidade.nome}
                    </MenuItem>
                  ))}
                
              </Select>
              {typeof (errorMessage) === 'string' && (
                <FormHelperText>{errorMessage}</FormHelperText>
              )}
            </FormControl>
          )}
          <div id="input-data">
            <FormControl
              classes={{ root: classes.root }}
              error={typeof (scheduleService) === 'string' || typeof (scheduleServiceUser) === 'string'}
              disabled={!unitySelected}
              variant="outlined"
              className={classes.formControl}
            >
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                  disableFuture={false}
                  error={typeof (scheduleService) === 'string'
                    || typeof (scheduleServiceUser) === 'string'}
                  shouldDisableDate={disableWeekends}
                  minDateMessage="Você não pode agendar em dias anteriores"
                  invalidDateMessage="A data informada é inválida"
                  minDate={new Date()}
                  InputProps={{
                    className: classes.menuItem,
                    classes: {
                      adornedEnd: classes.adornedEnd,
                    },
                  }}
                  PopoverProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: '#FFF',
                      },
                    },
                  }}
                  autoOk
                  disabled={!unitySelected}
                  variant="inline"
                  inputVariant="outlined"
                  label="Selecione o dia"
                  format="dd/MM/yyyy"
                  placeholder="00/00/0000"
                  value={selectedDate || null}
                  InputAdornmentProps={{ position: 'end', color: '#6B6D6E' }}
                  onChange={(date) => {
                    if (date && isValid(date)) {
                      handleDateChange(date);
                      if (unitySelected) {
                        getSchedulers(date, unitySelected);
                      }
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
              {typeof (scheduleService) === 'string' && (
                <FormHelperText>{scheduleService}</FormHelperText>
              )}
            </FormControl>
          </div>

          {loadingDate ? (
            <Box className={classes.boxLoadingIcon}>
              <CircularProgress />
            </Box>
          ) : (
          <div id="input-horario">
            <FormControl
              classes={{ root: classes.root }}
              disabled={!unitySelected || !selectedDate || typeof (scheduleService) === 'string'}
              variant="outlined"
              className={classes.formControl}
              error={typeof (scheduleServiceUser) === 'string'}
            >
              <InputLabel className={classes.textLabel} id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                classes={{
                  select: classes.menuItem,
                }}
                value={scheduleSelected || ''}
                MenuProps={{
                  MenuListProps: {
                    disablePadding: true,
                  },
                }}
                onChange={(event): void => {
                  setScheduleSelected(event.target.value as string);
                }}
                label="Escolha o horário"
              >
                {typeof (scheduleService) !== 'string' && scheduleService?.horarios.map((schedule) => (
                  <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                    {schedule}
                  </MenuItem>
                ))}
              </Select>
              {typeof (scheduleServiceUser) === 'string' && (
                <FormHelperText>{scheduleServiceUser}</FormHelperText>
              )}
            </FormControl>
            </div>)}
          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleReScheduler}
              disabled={
                loading 
                || !selectedDate 
                || !schedule?.ticket
                || !scheduleSelected
                || !unitySelected
              }
            >
              {!loading ? "Gerar" : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      </div>
       
        <div style={{ display: IsSavedScheduler ? 'initial' : 'none' }}>
          <DialogTitle className={classes.headerModal}>
            <Typography className={classes.titleModal}>
              Atendimento Gerado com Sucesso!
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.contentModal}>
            <MdCheckCircleOutline
              className={classes.icon}
            />
            <Box className={classes.boxInfo}>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}> {schedule?.user?.first_name}</Typography>
                <Typography className={classes.cpfText}>{cpfMask(schedule?.cpf || '')}</Typography>
              </Box>
              <Box className={classes.boxService}>
                <Typography className={classes.titleService}>Serviço</Typography>
                <Typography className={classes.titleBolder}> {schedule?.servico?.titulo}</Typography>
              </Box>
            <Box className={classes.boxService}>
              <Typography className={classes.titleService}>UNIDADE</Typography>
              <Typography className={classes.titleBolder}> {unitySelected || ''}</Typography>
            </Box>
            <Box className={classes.boxService}>
              <Typography className={classes.titleService}>DATA/HORA</Typography>
              <Typography className={classes.titleBolder}> {format(selectedDate || new Date(),"dd/MM/yyyy")} - {scheduleSelected || ' '}</Typography>
            </Box>
            </Box>

          </DialogContent>
          <DialogActions className={classes.footerModal}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.go(0)}
              className={classes.buttonClose}
            >
              Fechar
            </Button>
          </DialogActions>
        </div>
      
    </Dialog>
  );
}

export default memo(ReScheduler);