import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, PersonRounded } from "@material-ui/icons";
import useStyles from "./style";
import { Agendamento } from "../../../../interfaces/agendamento";
import { cpfMask } from "../../../../utils/cpfFormat";
import dateFormat from "../../../../utils/dateFormat";

interface Props {
  openDialog: boolean;
  handleClose: () => void;
  schedule: Agendamento;
  onActionButton: (value: boolean) => void;
}

const DialogAntecipate = ({
  openDialog,
  handleClose,
  schedule,
  onActionButton,
}: Props) => {
  const classes = useStyles();
  const matchesMobile = useMediaQuery("(min-width:576px)");

  const handleCancelSchedule = async () => {
    handleClose();
    onActionButton(true);
  };

  return (
    <Dialog
      fullWidth
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.header}>
        <Typography className={classes.textHeaderModal}>
          Antecipar agendamento
        </Typography>
        <IconButton onClick={handleClose} className={classes.buttonClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />

      {schedule && (
        <DialogContent>
          <PersonRounded fontSize="large" color="primary" />
          <Box className={classes.titleDependents}>
            <Typography className={classes.textModalTitleUDependent}>
              {schedule?.user?.first_name || schedule?.nome}
            </Typography>
            <Typography className={classes.textModalTitleUDependentCPF}>
              {schedule?.cpf?.length > 0 ? cpfMask(schedule.cpf) : (schedule?.user && cpfMask(schedule?.user?.username))}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>serviço</Typography>
            <Typography className={classes.textModal}>
              {schedule.servico.titulo}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>
              data/hora
            </Typography>
            <Typography className={classes.textModal}>
              {`${dateFormat(schedule.data)} às ${schedule.hora}`}
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.textModalTitle}>status</Typography>
            <Typography className={classes.textModal}>
              {schedule.status}
            </Typography>
          </Box>
          <Divider light />
          <Box>
            <Typography className={classes.textModalTitle}>
              Deseja antecipar o agendamento?
            </Typography>
            <Typography className={classes.textModal}>
              O agendamento atual será cancelado e um agendamento espontâneo
              será criado.
            </Typography>
          </Box>
          <Box className={classes.buttonCloseModal}>
            <Button
              className={classes.close}
              onClick={() => {
                handleClose();
                onActionButton(false);
              }}
              style={
                !matchesMobile
                  ? { width: "100%", textAlign: "center", padding: "15px 0" }
                  : {}
              }
            >
              fechar
            </Button>
            {matchesMobile && (
              <Button
                style={{ color: "#FFF", fontSize: 15 }}
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCancelSchedule}
              >
                Antecipar
              </Button>
            )}
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DialogAntecipate;
