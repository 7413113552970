import { Box, Button, CircularProgress, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "../../style";
import { ArrowDropDown, ArrowRight, ListAlt, People, Print } from "@material-ui/icons";
import PaginationService from "../../../../Components/PaginationService";
import { useSchedules } from "../../useSchedules";
import { cpfMask } from "../../../../utils/cpfFormat";
import dateFormat, { extractDateFormat, extractHourFormat } from "../../../../utils/dateFormat";
import { Fragment, useEffect, useState } from "react";
import { TabPanel } from "../SchedulesContent";
import { Agendamento } from "../../../../interfaces/agendamento";
import { TableRowCheckTime } from '../../../../Components';
import FormFilterAll from "../../../../Components/FormFilterAll";
import Modal156ListDemand from "../Modal156ListDemand";

type TabAllAttendanceProps = {
  value: number;
  index: number;
};

export default function TabAllAttendance(
  { value, index }: TabAllAttendanceProps
) {
  const {
    theme,
    classes,
    loadingAll,
    showDetails,
    setShowDetails,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    history,
    getAllAgendamentos,
    dataSchedulesAll,
    getHrefComprovante,
  } = useSchedules();
  const [open156, setOpen156] = useState(false);
  const [protocolSelected, setProtocolSelected] = useState('');
  const [cpfAtendenteSelected, setCpfAtendenteSelected] = useState('');


  const handleOpen156 = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || '');
    setCpfAtendenteSelected(schedule.atendente_id || '');
    setOpen156(true);
  }
  const [allParams, setAllParams] = useState<any>(undefined);
  const handleGetFromPage = async (page: number) => {
    await getAllAgendamentos(page, allParams);
  }
  const handleUpdateList = async (params?: any) => {
    setAllParams(params);
    await getAllAgendamentos(dataSchedulesAll?.current || 1, params);
  };

  const handleClearFilter = async () => {
    setAllParams(undefined);
    await getAllAgendamentos(1);
  }


  useEffect(() => {
    getAllAgendamentos();
  }, [getAllAgendamentos]);


  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Box
        style={{
          marginBottom: 25,
          width: "100%",
        }}
      >
        <FormFilterAll
          updateDataListFunction={handleUpdateList}
          clearDataListFunction={handleClearFilter}
        />
      </Box>

      <Box style={{ overflow: "auto" }}>

        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="25%">NOME</StyledTableCell>
              <StyledTableCell align="left">CPF</StyledTableCell>
              <StyledTableCell align="left">DATA/HORA</StyledTableCell>
              <StyledTableCell align="left">TIPO</StyledTableCell>
              <StyledTableCell align="left">STATUS</StyledTableCell>
              <StyledTableCell align="left" colSpan={2}>SERVIÇO</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingAll && <CircularProgress />}
            {!!dataSchedulesAll?.results?.length &&
              dataSchedulesAll.results.map((rowsSchedules: Agendamento) => (
                <Fragment key={rowsSchedules.ticket}>
                  <TableRowCheckTime
                    data={rowsSchedules.data}
                    hora={rowsSchedules.hora}
                    status={rowsSchedules.status}
                  >
                    <StyledTableCell component="th" scope="row">
                      {showDetails &&
                        showDetails === rowsSchedules.ticket ? (
                        <Button
                          onClick={() => {
                            setShowDetails(undefined);
                          }}
                        >
                          <ArrowDropDown />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setShowDetails(rowsSchedules.ticket);
                          }}
                        >
                          <ArrowRight />
                        </Button>
                      )}
                      {rowsSchedules?.user?.first_name || rowsSchedules?.nome}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {cpfMask(rowsSchedules.cpf)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`${dateFormat(rowsSchedules.data)} ${rowsSchedules.hora
                        }`}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.status}
                      align="left"
                    >
                      {rowsSchedules.tipo}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.status}
                      align="left"
                    >
                      {rowsSchedules.status}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {rowsSchedules.servico.titulo}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {rowsSchedules.para_dependente && rowsSchedules.dependentes && (
                        <Tooltip title="Dependentes">
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => {
                                setSchedulesDependentCurrent(rowsSchedules);
                                setOpenDependentsDialog(true);
                              }}

                            >
                              <People />
                            </Button>
                          </span>
                        </Tooltip>)}

                      {(rowsSchedules.tipo === "Online" && (rowsSchedules.status === "Concluído")) && (
                        <Tooltip title="Ir para atendimento">
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => history.push(`/atendimento/${rowsSchedules.id}`)}

                            >
                              <ArrowRight />
                            </Button>
                          </span>
                        </Tooltip>)}
                      <Tooltip title="Comprovante Agendamento">
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            component="a"
                            href={getHrefComprovante(rowsSchedules.ticket)}
                            target="_blank"
                            className={classes.buttonIcon}
                          >
                            <Print />
                          </Button>
                        </span>
                      </Tooltip>

                      {((rowsSchedules.status === "Concluído"
                        || rowsSchedules.status === "Congelado"
                        || rowsSchedules.status === "Em Andamento")
                        &&
                        rowsSchedules.atendente_id?.length) && (<Tooltip title="Demandas do agendamento">
                          <span >
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => handleOpen156(rowsSchedules)}
                              className={classes.buttonIcon}
                            >
                              <ListAlt />
                            </Button>
                          </span>
                        </Tooltip>)}
                    </StyledTableCell>

                  </TableRowCheckTime>

                  {showDetails &&
                    showDetails === rowsSchedules.ticket && (
                      <StyledTableRow
                        key={rowsSchedules.ticket}
                        style={{
                          borderTopStyle: "dotted",
                          borderTopWidth: 1.4,
                        }}
                      >
                        <StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                            style={{ paddingLeft: 60 }}
                          >
                            INICIO DO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                            style={{ paddingLeft: 60 }}
                          >
                            {rowsSchedules.status ===
                              "Não Compareceu"
                              ? "Não Compareceu"
                              : `${rowsSchedules.hora_inicio &&
                              extractDateFormat(
                                rowsSchedules.hora_inicio
                              )
                              } às ${rowsSchedules.hora_inicio &&
                              extractHourFormat(
                                rowsSchedules.hora_inicio
                              )
                              }`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            FIM DO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedules.status ===
                              "Não Compareceu"
                              ? "Não Compareceu"
                              : `${extractDateFormat(
                                rowsSchedules.updated_at
                              )} às ${extractHourFormat(
                                rowsSchedules.updated_at
                              )}`}
                          </Typography>
                        </StyledTableCell>

                        {rowsSchedules?.protocolo && (<StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            PROTOCOLO AGENDAMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedules?.protocolo}
                          </Typography>
                        </StyledTableCell>)}
                        {rowsSchedules?.protocolo_atendimento && (<StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            PROTOCOLO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedules?.protocolo_atendimento}
                          </Typography>
                        </StyledTableCell>)}

                        <StyledTableCell colSpan={3}>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            DESCRIÇÃO / OBSERVAÇÕES:
                          </Typography>
                          {rowsSchedules?.observacao_atendimento?.length ? (
                            rowsSchedules.observacao_atendimento.map(
                              (observacao) => (
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {`(${extractDateFormat(
                                    observacao.updated_at
                                  )} às ${extractHourFormat(
                                    observacao.updated_at
                                  )}) - ${observacao.conteudo} `}
                                </Typography>
                              )
                            )
                          ) : (
                            <Typography
                              className={classes.textValueDetails}
                            >
                              {'Sem Observações'}

                            </Typography>
                          )}

                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedules?.descricao_atendimento || ''}

                          </Typography>

                        </StyledTableCell>
                      </StyledTableRow>
                    )}

                </Fragment>
              ))}
            {!!!dataSchedulesAll?.results?.length && !loadingAll && <TableRow>
              <StyledTableCell colSpan={7} align="center">
                <h2>Nenhum resultado encontrado</h2>
              </StyledTableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </Box>
      <PaginationService
        getSearch={handleGetFromPage}
        listSearch={{
          count: dataSchedulesAll?.count,
          next: dataSchedulesAll?.next,
          previous: dataSchedulesAll?.previous,
          current: dataSchedulesAll?.current,
          total_pages: dataSchedulesAll?.total_pages,
        }}
      />
      <Modal156ListDemand
        open={open156}
        setOpen={setOpen156}
        protocol={protocolSelected}
        cpf_atendente={cpfAtendenteSelected}
      />
    </TabPanel>
  );
};