import React, { createContext, useContext, useReducer } from "react";

export const ScheduleContext = createContext<any>({});

export const ScheduleProvider = ({
  reducer,
  initialState,
  children,
}: any) => {

  return (
    <ScheduleContext.Provider value={useReducer(reducer, initialState)}>
    {children}
    </ScheduleContext.Provider>
)};

export const useStateScheduleValue = ()=> {
  return useContext(ScheduleContext);
};
