import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  getUserData as getUserDataRequest,
  logout,
} from "../../services/auth";
import useStyles from "./style";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import { getGuiche } from "../../services/giche";

export default function SetTicketOffice(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const [{ user, currentTicketOffice }, dispatch] = useStateValue();

  const token: string | null = sessionStorage.getItem("gov_access_token_sso");
  const tokenSiseci: string | null = sessionStorage.getItem(
    "gov_access_token_sso"
  );

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      dispatch({
        type: actionTypes.SET_USER,
        user: data,
      });
      sessionStorage.setItem("gov_user_data", JSON.stringify(data));
    } catch (err) {

      console.log(err);
      logout();
    }
  };

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requestCurrentTicketOffice = async () => {
      try {
        const { data } = await getGuiche(tokenSiseci || "");
        if (data?.results?.length) {
          dispatch({
            type: actionTypes.SET_TICKET_OFFICE,
            currentTicketOffice: data.results[0],
          });
        } else {
          await logout();
        }
      } catch (error) {
        console.log(error);
      } finally {

      }
    };
    if (user) {
      requestCurrentTicketOffice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if ((user && currentTicketOffice)) {
       const pathname = sessionStorage.getItem("pathname_redirect_gov");
       const spathname = pathname === "/" ? "/home" : pathname;
       history.push(spathname || "/home");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentTicketOffice]);

  return (
    <Box className={classes.main}>
      <img
        src="/assets/images/SupportAgent.svg"
        className={classes.icon}
        alt="logo Atendimento"
      />
      <Typography className={classes.text}>Atendimento</Typography>
      <CircularProgress className={classes.loading} />
    </Box>
  );
}
