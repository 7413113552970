import { Box, Button, CircularProgress, IconButton, Table, TableBody, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { StyledTableCell, StyledTableRow } from "../../style";
import { TabPanel } from "../SchedulesContent";
import { ArrowDropDown, ArrowRight, ListAlt, People, Print, Search, SyncAltRounded } from "@material-ui/icons";
import PaginationService from "../../../../Components/PaginationService";
import { cpfMask } from "../../../../utils/cpfFormat";
import dateFormat, { extractDateFormat, extractHourFormat } from "../../../../utils/dateFormat";
import { useSchedules } from "../../useSchedules";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { isValid } from "date-fns";
import InputMask from "react-input-mask";
import { Fragment, useEffect, useState } from "react";
import { Agendamento } from "../../../../interfaces/agendamento";
import { TableRowCheckTime } from '../../../../Components';
import Modal156ListDemand from "../Modal156ListDemand";


type TabOldsAttendanceProps = {
  value: number;
  index: number;
};
export default function TabOldsAttendance({ value, index }: TabOldsAttendanceProps) {

  const {
    theme,
    filter,
    changeAutoComplete,
    matchesMobile,
    classes,
    selectedDate,
    handleDateChange,
    valueFilter,
    setValueFilter,
    searchUSer,
    loadingOlds,
    showDetails,
    setShowDetails,
    setSchedulesDependentCurrent,
    setOpenDependentsDialog,
    history,
    agendamentosAnteriores,
    dataSchedulesOlds,
    getHrefComprovante,
  } = useSchedules();

  const [open156, setOpen156] = useState(false);
  const [protocolSelected, setProtocolSelected] = useState('');
  const [cpfAtendenteSelected, setCpfAtendenteSelected] = useState('');


  const handleOpen156 = (schedule: Agendamento) => {
    setProtocolSelected(schedule.protocolo || '');
    setCpfAtendenteSelected(schedule.atendente_id || '');
    setOpen156(true);
  }

  useEffect(() => {
    agendamentosAnteriores();
  }, [agendamentosAnteriores]);

  return (
    <TabPanel value={value} index={index} dir={theme.direction}>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        style={{ width: "100%" }}
      >
        <Autocomplete
          id="controllable-states-demo"
          options={["Data", "CPF"]}
          value={filter}
          onChange={changeAutoComplete}
          style={{
            width: !matchesMobile ? "100%" : 162,
            marginBottom: 15,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Filtrar por"
            />
          )}
        />
        {matchesMobile && (
          <IconButton>
            <SyncAltRounded />
          </IconButton>
        )}

        {filter && filter === "Data" && (
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableFuture={false}
              invalidDateMessage="A data informada é inválida"
              InputProps={{
                className: classes.menuItem,
                classes: { adornedEnd: classes.adornedEnd },
              }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Selecione o dia"
              format="dd/MM/yyyy"
              value={selectedDate}
              InputAdornmentProps={{ position: "end", color: "#000" }}
              onChange={(date) => {
                if (date && isValid(date)) {
                  handleDateChange(date);
                }
              }}
            />
          </MuiPickersUtilsProvider>
        )}

        {filter && filter === "CPF" && (
          <>
            <InputMask
              value={valueFilter}
              onChange={(event: any): void => {
                if (event.target.value) {
                  setValueFilter(event.target.value);
                }
              }}
              mask="999.999.999-99"
              disabled={filter === undefined}
            >
              {() => (
                <TextField
                  placeholder="Ex: 000.000.000-00"
                  disabled={filter === undefined}
                  style={{
                    width: !matchesMobile ? "100%" : 235,
                    marginBottom: 15,
                    marginRight: matchesMobile ? 15 : 0,
                  }}
                  variant="outlined"
                />
              )}
            </InputMask>
          </>
        )}
        {(!filter) && (
          <Autocomplete
            id="controllable-states-demo"
            options={[{ name: "" }]}
            getOptionLabel={(filter) => filter.name}
            style={{
              width: !matchesMobile ? "100%" : 235,
              marginBottom: 15,
              marginRight: matchesMobile ? 15 : 0,
            }}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="-------------"
              />
            )}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonSearch}
          style={!matchesMobile ? { width: "100%", margin: 0 } : {}}
          onClick={searchUSer}
          startIcon={<Search />}
        >
          BUSCAR
        </Button>
      </Box>
      <Box style={{ overflow: "auto" }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="25%">NOME</StyledTableCell>
              <StyledTableCell align="left">CPF</StyledTableCell>
              <StyledTableCell align="left">DATA/HORA</StyledTableCell>
              <StyledTableCell align="left">TIPO</StyledTableCell>
              <StyledTableCell align="left">STATUS</StyledTableCell>
              <StyledTableCell align="left" colSpan={2}>SERVIÇO</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingOlds && <CircularProgress />}
            {!!dataSchedulesOlds?.results?.length &&
              dataSchedulesOlds.results.map((rowsSchedulesPreviou: Agendamento) => (
                <Fragment  key={`${rowsSchedulesPreviou.ticket}-key-${rowsSchedulesPreviou.cpf}`}>
                  <TableRowCheckTime
                    data={rowsSchedulesPreviou.data}
                    hora={rowsSchedulesPreviou.hora}
                    status={rowsSchedulesPreviou.status}
                  >
                    <StyledTableCell component="th" scope="row">
                      {showDetails &&
                        showDetails === rowsSchedulesPreviou.ticket ? (
                        <Button
                          onClick={() => {
                            setShowDetails(undefined);
                          }}
                        >
                          <ArrowDropDown />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setShowDetails(rowsSchedulesPreviou.ticket);
                          }}
                        >
                          <ArrowRight />
                        </Button>
                      )}
                      {rowsSchedulesPreviou?.user?.first_name || rowsSchedulesPreviou?.nome}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {cpfMask(rowsSchedulesPreviou.cpf)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`${dateFormat(rowsSchedulesPreviou.data)} ${rowsSchedulesPreviou.hora
                        }`}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.status}
                      align="left"
                    >
                      {rowsSchedulesPreviou.tipo}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.status}
                      align="left"
                    >
                      {rowsSchedulesPreviou.status}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {rowsSchedulesPreviou.servico.titulo}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {rowsSchedulesPreviou.para_dependente && rowsSchedulesPreviou.dependentes && (
                        <Tooltip title="Dependentes">
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => {
                                setSchedulesDependentCurrent(rowsSchedulesPreviou);
                                setOpenDependentsDialog(true);
                              }}
                            >
                              <People />
                            </Button>
                          </span>
                        </Tooltip>
                      )}

                      {(rowsSchedulesPreviou.tipo === "Online" && (rowsSchedulesPreviou.status === "Concluído")) && (
                        <Tooltip title="Ir para atendimento">
                          <span>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonIcon}
                              onClick={() => history.push(`/atendimento/${rowsSchedulesPreviou.id}`)}

                            >
                              <ArrowRight />
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      <Tooltip title="Comprovante Agendamento">
                        <span >
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            component="a"
                            href={getHrefComprovante(rowsSchedulesPreviou.ticket)}
                            target="_blank"
                            className={classes.buttonIcon}
                          >
                            <Print />
                          </Button>
                        </span>
                      </Tooltip>
                      {((rowsSchedulesPreviou.status === "Concluído"
                        || rowsSchedulesPreviou.status === "Congelado"
                        || rowsSchedulesPreviou.status === "Em Andamento")
                        &&
                        rowsSchedulesPreviou.atendente_id?.length ) && (<Tooltip title="Demandas do agendamento">
                        <span >
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => handleOpen156(rowsSchedulesPreviou)}
                            className={classes.buttonIcon}
                          >
                            <ListAlt />
                          </Button>
                        </span>
                      </Tooltip>)}


                    </StyledTableCell>

                  </TableRowCheckTime>

                  {showDetails &&
                    showDetails === rowsSchedulesPreviou.ticket && (
                      <StyledTableRow
                        key={rowsSchedulesPreviou.ticket}
                        style={{
                          borderTopStyle: "dotted",
                          borderTopWidth: 1.4,
                        }}
                      >
                        <StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                            style={{ paddingLeft: 60 }}
                          >
                            INICIO DO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                            style={{ paddingLeft: 60 }}
                          >
                            {rowsSchedulesPreviou.status ===
                              "Não Compareceu"
                              ? "Não Compareceu"
                              : `${rowsSchedulesPreviou.hora_inicio &&
                              extractDateFormat(
                                rowsSchedulesPreviou.hora_inicio
                              )
                              } às ${rowsSchedulesPreviou.hora_inicio &&
                              extractHourFormat(
                                rowsSchedulesPreviou.hora_inicio
                              )
                              }`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            FIM DO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedulesPreviou.status ===
                              "Não Compareceu"
                              ? "Não Compareceu"
                              : `${extractDateFormat(
                                rowsSchedulesPreviou.updated_at
                              )} às ${extractHourFormat(
                                rowsSchedulesPreviou.updated_at
                              )}`}
                          </Typography>
                        </StyledTableCell>

                        {rowsSchedulesPreviou?.protocolo && (<StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            PROTOCOLO AGENDAMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedulesPreviou?.protocolo}
                          </Typography>
                        </StyledTableCell>)}
                        {rowsSchedulesPreviou?.protocolo_atendimento && (<StyledTableCell>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                            PROTOCOLO ATENDIMENTO
                          </Typography>
                          <Typography
                            className={classes.textValueDetails}
                          >
                            {rowsSchedulesPreviou?.protocolo_atendimento}
                          </Typography>
                        </StyledTableCell>)}

                        <StyledTableCell colSpan={3}>
                          <Typography
                            className={classes.textTitleDetails}
                          >
                          DESCRIÇÃO / OBSERVAÇÕES:
                          </Typography>
                          {rowsSchedulesPreviou?.observacao_agendamento?.length ? (
                            rowsSchedulesPreviou.observacao_agendamento.map(
                              (observacao) => (
                                <Typography
                                  className={classes.textValueDetails}
                                >
                                  {`(${extractDateFormat(
                                    observacao.updated_at
                                  )} às ${extractHourFormat(
                                    observacao.updated_at
                                  )}) - ${observacao.conteudo} `}
                                </Typography>
                              )
                            )
                          ) : (
                            <Typography
                              className={classes.textValueDetails}
                            >
                              Sem Observações
                            </Typography>
                          )}
                        <Typography
                          className={classes.textValueDetails}
                        >
                         {rowsSchedulesPreviou?.descricao_atendimento || ''}
                        </Typography>
                        </StyledTableCell>
                      </StyledTableRow>

                    )}
                </Fragment>
              ))}
            {!!!dataSchedulesOlds?.results?.length && !loadingOlds && (
              <TableRow>
                <StyledTableCell colSpan={7} align="center">
                  <h2>Nenhum agendamento para hoje</h2>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <PaginationService
        getSearch={agendamentosAnteriores}
        listSearch={{
          count: dataSchedulesOlds?.count,
          next: dataSchedulesOlds?.next,
          previous: dataSchedulesOlds?.previous,
          current: dataSchedulesOlds?.current,
          total_pages: dataSchedulesOlds?.total_pages,
        }}
      />

      <Modal156ListDemand
        open={open156}
        setOpen={setOpen156}
        protocol={protocolSelected}
        cpf_atendente={cpfAtendenteSelected}
      />
    </TabPanel>
  );
};