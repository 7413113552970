import { AxiosResponse } from "axios";
import { apisiseci } from "./api";

export const getAtendimentos = (
  token: string,
  params?: {
    hoje?: boolean;
    anteriores?: boolean;
    page?: number;
    data?: string;
    status?:
      | "Aguardando"
      | "Em Andamento"
      | "Pendente"
      | "Concluído"
      | "Cancelado";
    guiche_id: string;
  },
): Promise<AxiosResponse> =>
  apisiseci.get("agendamento/atendimentos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const updateStatusAtendimento = (
  token: string,
  id: string,
  status:
    | "Aguardando"
    | "Em Andamento"
    | "Pendente"
    | "Concluído"
    | "Cancelado"
    | "Não Compareceu",
  guiche: string
): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/atendimentos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAtendimento = (
  token: string,
  id: string,
  guiche_id: string,
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/agendamentos/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      guiche_id,
    },
  });

  export const sendReply = (
    token: string,
    id: string,
    message: string,
    haveAnexo?: boolean
  ): Promise<AxiosResponse> =>
    apisiseci.post(
      "agendamento/responder/",
      {
        agendamento: id,
        conteudo: message,
        anexo: haveAnexo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );


export const attachFilesOnReply = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  resposta: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("titulo", titulo);
  formData.append("descricao", descricao);
  formData.append("arquivo", arquivo);
  formData.append("resposta", resposta);
  return apisiseci.post("agendamento/anexar_resposta/", formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const markAsRead = (token: string, replayId: number): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/marcar_resposta/${replayId}/`,
    {
      lido: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addObservationAttendence = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/observacao/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );