import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    color: 'rgba(55, 63, 65, 0.6);',
  },
  body: {
    minWidth: 145,
    fontSize: 16,
    color: '#373F41',
    justifyItems: 'center',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F7F8FC',
    },
  },
}))(TableRow);

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'space-between',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  main: {
    width: '90%',
    margin: 'auto',
  },
  mainTable: {
    width: '100%',
    padding: 10,
    borderRadius: 8,
  },
  tableDatail: {
    fontSize: 24,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
  },
  titleTable: {
    background: '#053396',
    color: '#FFFFFF',
    height: 64,
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  textSchedules: {
    fontSize: 24,
    letterSpacing: 0.3,
    fontFamily: 'Mulish',
    color: '#373F41',
    margin: '15px 0px',
  },
  headerTable: {
    height: 64,
    borderBottom: '1px solid #E3E3E3',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 14,
    textAlign: 'left',
    padding: 10,
  },
  dataTable: {
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: 16,
    padding: 10,
    height: 80,
    color: '#373F41',
  },
  dataTableActions: {
    width: 200,
  },
  listSchedules: {
    width: '100%',
    borderRadius: 4,
  },
  status: {
    color: theme.palette.primary.main,
  },
  tabLabel: {
    textTransform: 'capitalize',
    fontSize: 16,
    margin: '32px 0px 3px',
  },
  buttonIcon: {
    minWidth: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    margin: 2,
  },
  buttonIconAttention: {
    minWidth: 40,
    height: 40,
    backgroundColor: '#E15050',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#E15050',
    },
  },
  buttonSearch: {
    height: 54,
    minWidth: 133,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'Mulish',
    letterSpacing: 0.3,
  },
  buttonCancelSearch: {
    height: 54,
    minWidth: 133,
    color: '#ff9b38',
    borderColor: '#ff9b38',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'Mulish',
    letterSpacing: 0.3,
    margin: '0px 8px 0px 8px',
  },
  paginationClass: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    '& .Mui-selected': {
      borderRadius: 0,
    },
  },
  textTitleDetails: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Mulish',
  },
  textValueDetails: {
    color: '#373F41',
    fontSize: 14,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    marginLeft: 4,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    marginLeft: 10,
  },
  buttonAttendance: {
    maxWidth: 220,
    width: '100%',
    height: 48,
    fontSize: 14,
    textTransform: 'uppercase',
    marginTop: 16,
    borderRadius: 4,
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTopAlt: {
    display: 'flex',
    flexDirection: 'column',
  },
  BoxMenuOptions: {
    padding: 8,
  },
  itemMenuOption:  {
    width: 240,
    display: 'flex',
    height: 56,
    alignContent:'center',
    alignItems: 'center',
  },
  textMenuOption: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'normal',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: '#053396',    
  },
  iconMenuOption: {
    fontSize: theme.typography.pxToRem(25),
    paddingRight: 10
  },
}));

export default useStyles;
